.radio-input {
  position: relative;
  width: 100%;
  cursor: pointer;
  margin: 0;

  $root: &;

  &--disabled {
    opacity: .5;
    cursor: not-allowed;
    user-select: none;
  }

  &__wrapper {
    position: relative;
    display: block;
    padding-left: 25px;
  }

  &__input {
    visibility: hidden;
    height: 0;
    width: 0;
    z-index: -1;
    position: absolute;

    &:checked {
      + #{$root}__radio {
        border-color: $black;

        &::before {
          content: '';
          position: absolute;
          height: .625rem;
          width: .625rem;
          border-radius: 50%;
          background: $black;
          display: block;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      ~ #{$root}__label {
        color: $grey-900;
      }

      ~ #{$root}__thumb {
        border-color: $black;
      }

      ~ #{$root}__box {
        color: $black;
        border-color: $black;
      }
    }

    &:disabled {
      + #{$root}__radio,
      ~ #{$root}__label,
      ~ #{$root}__box,
      + #{$root}__thumb {
        opacity: .5;
        cursor: not-allowed;
      }
    }
  }

  &__radio {
    width: 19px;
    height: 19px;
    border: 1px solid $grey-100;
    background: $white;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__label {
    color: $grey-500;
    line-height: 1.3;
    font-size: $baseFontSize;
    font-weight: $fontWeightRegular;
    padding: 0 30px;
  }

  &__label-sub {
    color: $grey-500;
  }

  &__thumb {
    display: inline-block;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 15%;
    padding: 2px;
    overflow: hidden;
  }

  &__box {
    position: relative;
    height: 40px;
    width: auto;
    min-width: 40px;
    text-align: center;
    padding: 0 5px;
    border: 2px solid $grey-100;
    font-weight: $fontWeightSemiBold;
    font-size: $fontSizeSmall;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: $black;
      border-color: $black;

      #{$root}__input:disabled ~ &,
      #{$root}--disabled & {
        color: $black;
        opacity: .5;
        border-color: $grey-100;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      top: 0;
      left: 0;
      border: 2px solid $white;
    }
  }
}
