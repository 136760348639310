.modal {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  background: $white;
  width: 45rem;
  max-width: 100%;
  max-height: 100%;
  z-index: $indexModal;
  text-align: left;
  padding: 2rem 2rem 4rem;
  display: flex;
  flex-direction: column;

  @include media('>=tablet') {
    padding: 3rem 4rem 4rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding-bottom: .5rem;
    align-items: start;
    width: 100%;

    &-headline {
      margin-top: 2rem;
    }

    &--justify-end {
      justify-content: end;
    }
  }

  &__footer {
    padding-top: 1rem;
    margin-bottom: -2rem;
    text-align: center;

    @include media('>=tablet') {
      margin-bottom: -1rem;
    }
  }

  &.left {
    left: 0;
    right: auto;
    width: auto;
    max-width: 35rem;

    @include media('<tablet') {
      max-width: none;
      right: 0;
    }
  }

  &.gallery {
    .product-gallery__thumbnails {
      margin: auto;
    }
  }

  &__content {
    max-height: calc(100vh - 4rem);
    padding-bottom: 1rem;
    height: 100%;
    overflow: hidden auto;

    &--extended {
      max-height: calc(100vh - 2rem);
      padding-bottom: 0;
    }

    @include media('>=tablet') {
      max-height: calc(100vh - 8rem);

      &--extended {
        max-height: calc(100vh - 2rem);
      }
    }
  }

  &__close-button {
    color: $black;
    cursor: pointer;
    width: 2.25rem;
    height: 2.25rem;
    position: relative;
    margin-right: -.5rem;

    @include iconFont('close') {
      font-size: 2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.modal-open {
  position: relative;

  /* fix for modals inside sticky header -> improvement: put modal outside header */
  .header__sticky--active {
    position: absolute;
    will-change: initial;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
