.search-autosuggest-box {
  height: 100%;

  &__image {
    width: 100%;
    height: 100%;
    /* stylelint-disable-next-line */
    aspect-ratio: 4 / 3 ;
  }

  &__name {
    color: $black;
    box-sizing: content-box;
    padding-top: 5px;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
