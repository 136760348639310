$indexFlyout: 200;
$indexHeader: 500;
$indexTooltip: 600;
$indexBasketFlyout: 750;
$indexImagePreview: 900;
$indexFullScreenGallery: 1100;
$indexPageOverlay: 1200;
$indexSearchPageOverlay: 100;
$indexCookies: 1250;
$indexBurgerMenu: 1300;
$indexPlpFilters: 1400;
$indexModal: 1500;
