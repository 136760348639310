.header-meta-content {
  &__contact {
    font-size: $fontSizeSmall;
  }

  &__links {
    display: flex;
  }

  &__link {
    color: $black;
    text-decoration: none;
    display: inline-block;
    position: relative;
  }

  @include media('>laptop') {
    padding-top: 10px;
    padding-bottom: 10px;

    &__contact {
      display: block;
    }

    &__phone {
      display: inline-block;
      color: $black;
      text-decoration: none;
      position: relative;
      padding-left: 30px;
      font-weight: $fontWeightMedium;

      @include iconFont(phone) {
        position: absolute;
        color: $brandPrimaryColor;
        left: 0;
        top: 50%;
        transform: translateY(-50%) rotateY(180deg);
        font-size: 20px;
      }
    }

    &__phone-info {
      margin-left: 10px;
      display: inline-block;
      color: $grey-500;
    }

    &__links {
      justify-content: flex-end;
    }

    &__link {
      font-size: $baseFontSize;
      padding: 0 10px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include media('<=laptop') {
    &__contact {
      display: none;
    }

    &__links {
      flex-direction: column;
    }

    &__link {
      font-size: $baseFontSize;
      padding: 10px 15px;
      border-bottom: 1px solid $grey-900;
    }
  }
}
