.product-info {
  &__wrapper {
    &--headline {
      margin-bottom: 2rem;

      & > div {
        line-height: 150%;
      }
    }
  }

  &__headline {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    -webkit-line-clamp: 3;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @include media('>=laptop') {
      gap: 4.5rem;
      flex-direction: inherit;
    }

    &--additional {
      &-wrapper {
        flex: 1;
        flex-direction: column;
        justify-content: left;
        align-items: start;
      }

      &-header {
        font-weight: bold;
      }
    }
  }

  &__product-number {
    color: $grey-800;
  }

  &__available-parts {
    cursor: pointer;
    position: relative;
    margin-bottom: 2rem;
    padding-left: 2rem;

    @include iconFont(arrowDown) {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      color: $grey-800;
    }
  }

  &__buttons {
    &.available-parts__buttons {
      justify-content: left;
      margin-top: 2rem;
    }
  }

  &__list {
    @include clearList;

    color: $grey-800;

    li {
      margin-top: .5rem;
    }
  }

  &__in-selected-car-model {
    margin-top: 1rem;

    &--text {
      color: $signalRedDark;
    }
  }

  &__bundle-price {
    margin-top: 2rem;
  }
}
