/* // PRELOADER */

/* stylelint-disable-next-line */
filter-category,
[is="filter-category"] {
  .filter-category__preloader {
    @include componentPreloader {
      display: block;
      width: 100%;
      height: 18px;
      margin: 5px 0;
    }
  }
}
