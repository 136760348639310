.select-car-model-motorizations {
  &__back {
    cursor: pointer;
    margin: 2rem 0;

    &:hover,
    &--focus {
      border-color: $black;
    }

    @include iconFont(arrowLeft, before) {
      font-size: 1.3rem;
      margin-right: .2rem;
      transform: translateY(15%);
    }
  }

  &__selected-car-model {
    padding-bottom: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;

    @include iconFont(car, before) {
      font-size: 1rem;
      margin-right: .5rem;
      transform: translateY(10%);
    }
  }

  &__motorization {
    @include iconFont(engine, before) {
      font-size: 1rem;
      margin-right: .5rem;
      transform: translateY(10%);
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }
}
