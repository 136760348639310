.login-layout > div {
  display: block;
  height: auto;
  min-height: auto;

  .page-main {
    max-height: 100vh;
    height: auto;
    min-height: 100vh;
    padding-bottom: 0;
  }
}

.login-page {
  margin: 0;
  padding: 0;
  min-height: inherit;

  &__grid_container {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr auto;
    min-height: inherit;

    @include media('<tablet') {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: $black;
    }
  }

  &__image-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    grid-row: 1 / span 1;
    grid-column: 2 / span 1;

    @include media('<tablet') {
      display: none;
    }
  }

  &__logo-container {
    display: flex;
    align-items: flex-end;
    grid-row: 2 / span 1;
    grid-column: 1 / span 1;
    padding: 5rem;
    background: $black;

    @include media('<tablet') {
      padding: 2rem;
    }
  }

  &__logo {
    @include media('<1100px') {
      max-width: 220px;
    }

    @include media('<tablet') {
      width: 100%;
      margin: auto;
    }
  }

  &__image-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 100%;
    min-width: 100%;
  }

  &__terms {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    height: 100%;
    grid-row: 2 / span 1;
    grid-column: 2 / span 1;

    @include media('<tablet') {
      background-color: $black;
      padding: 2rem;
      height: auto;
    }

    &--top {
      @include media('<tablet') {
        display: none;
      }

      @include media('<1100px') {
        padding-bottom: 1rem;
      }
    }

    &_link {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;

      @include media('<1000px') {
        flex-direction: column;
      }

      @include media('<tablet') {
        flex-direction: row-reverse;
        place-content: flex-end space-between;
      }

      @include media('<phone') {
        flex-direction: column;
      }

      &_content {
        display: flex;
        justify-content: start;

        @include media('<tablet') {
          flex-direction: column;
          align-items: end;
          justify-content: space-between;
          margin-left: 1rem;
        }

        @include media('<phone') {
          flex-direction: row;
          width: 100%;
        }

        a {
          margin-right: 1rem;
          color: $black;
          text-decoration: none;

          @include media('<tablet') {
            color: $white;
            margin-right: 0;
          }
        }
      }
    }
  }

  &__info {
    max-height: 200px;
    overflow-y: auto;
  }

  &__copyright {
    margin: 0;
    padding-top: 1rem;
    color: $grey-400;

    @include media('<tablet') {
      align-self: end;
    }
  }

  &__btn_login {
    width: 100%;
    margin-top: 1rem;
  }

  &__header-container {
    @include media('<tablet') {
      padding: 2rem;
    }

    background-color: $black;
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    padding: 5rem;
    color: $white;
  }
}
