.general-message {
  position: relative;
  padding: 20px 0;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;

  &--type {
    &_info {
      background: $grey-700;
    }

    &_error {
      background: $brandPrimaryColor;
    }

    &_warning {
      background: $signalRedLight;
    }

    &_success {
      background: $signalGreenLight;
    }
  }

  &__messages-list {
    @include clearList;
  }

  &__message {
    color: $white;
    font-size: $baseFontSize;
  }

  &__close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -10px;
    right: -10px;
    background: $brandPrimaryColor;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 80%);

    @include iconFont('close') {
      font-size: 10px;
      color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @include media('>=tablet') {
      width: 24px;
      height: 24px;
      position: absolute;
      top: -12px;
      right: -12px;

      &::before {
        font-size: 12px;
      }
    }
  }
}
