.part-gallery {
  cursor: pointer;
  display: flex;
  gap: 1rem;

  &--image {
    @include media('>=tablet') {
      width: 25%;
    }
  }

  &__additional-images {
    position: relative;

    &--counter {
      position: absolute;
      inset: 0;
      background: rgba($white, .5);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  span:only-child {
    max-width: calc(50% - 1rem);
  }

  &__image {
    display: block;
    height: auto;
    max-width: 100%;
    width: 155px;
  }
}
