.swatch-color {
  &_weiss,
  &_white {
    background: #fff;
  }

  &_schwarz,
  &_black {
    background: #000;
  }

  &_gelb,
  &_yellow {
    background: #f9ca24;
  }

  &_orange {
    background: #f0932b;
  }

  &_rot,
  &_red {
    background: #eb2f06;
  }

  &_rosa,
  &_pink {
    background: #ff6b81;
  }

  &_lila,
  &_purple {
    background: #b53471;
  }

  &_blau,
  &_blue {
    background: #0652dd;
  }

  &_gruen,
  &_green {
    background: #2ed573;
  }

  &_braun,
  &_brown {
    background: #d35400;
  }

  &_natur,
  &_nature {
    background: #27ae60;
  }

  &_grau,
  &_grey {
    background: #95a5a6;
  }

  &_gold {
    background: #ffd32a;
  }

  &_silber,
  &_silver {
    background: #bdc3c7;
  }

  &_bunt,
  &_coloured {
    background: #2c3e50;
  }
}
