.cookie-box {
  background: $white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -3px 5px 0 rgb(0 0 0 / 15%);
  z-index: $indexCookies;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;

    @include container;
  }

  &__text {
    color: $grey-900;
    line-height: 1.2;
    flex: 1 1 auto;
    padding-right: 15px;

    @include fontSize($baseFontSize);

    @include media('>=tablet') {
      padding-right: 20px;
    }

    strong {
      font-weight: $fontWeightSemiBold;
      color: $grey-900;
      display: block;
    }

    a,
    a:visited {
      color: $brandPrimaryColor;
      text-decoration: underline;
    }
  }

  &__close-button {
    flex: 0 1 220px;
  }
}
