.content-search-results {
  &__headline {
    font-size: $fontSizeXXMedium;
    margin-bottom: 20px;
  }

  &__result {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid $grey-100;
    text-decoration: none;
    transition: border .2s;
    display: block;
    padding: 15px;
    border-radius: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      border: 1px solid $grey-200;
      text-decoration: none;
    }
  }
}
