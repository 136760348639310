.cart-select {
  &__wrapper {
    margin-bottom: 10px;
  }

  &__new-cart {
    width: 100%;
    color: $brandPrimaryColor;
    background: $white;

    &--label::after {
      transform: rotate(90deg);
    }

    &--label::before,
    &--label::after {
      content: '';
      width: 16px;
      height: 2px;
      display: block;
      position: absolute;
      top: 50%;
      background: $grey-500;
      border-radius: 2px;
    }
  }

  &__select {
    .select__custom-option {
      padding: .5rem;
    }
  }

  &__modal {
    &--form,
    &--headline {
      padding: 20px;

      @include media("<tablet") {
        padding: 5px;
      }
    }

    &--button {
      line-height: 1;
      height: 42px;
      padding: 0 20px;
    }

    &--add-user {
      cursor: pointer;

      @include iconFont(add, before, true) {
        font-size: 32px;
      }

      &:hover {
        color: $brandPrimaryColor;
      }
    }
  }

  &__option {
    &--label {
      flex: 1;
      padding-left: .5rem;
    }

    &--not-shared {
      @include iconFont(person) {
        display: block;
        font-size: 1.25rem;
      }
    }

    &--shared {
      @include iconFont(group) {
        display: block;
        font-size: 1.25em;
      }
    }

    &--delete {
      @include iconFont(trash) {
        display: block;
        font-size: 1.25rem;
      }
    }

    &--empty {
      width: 1.25rem;
    }

    &--edit {
      @include iconFont(edit) {
        display: block;
        font-size: 1.25rem;
      }
    }
  }

  &__button-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 20px;

    @include media(">phone") {
      flex-direction: row;
    }
  }
}
