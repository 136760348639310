.category-page {
  @include container;

  &__header {
    margin-bottom: 30px;

    @include media('>=tablet') {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__name {
    @include fontSize($fontSizeLarge);

    display: block;
    width: 100%;
    margin: 0 0 20px;
    padding: 0;
    font-weight: $fontWeightSemiBold;

    @include media('>=tablet') {
      margin: 0;
    }
  }

  &__show-all {
    width: 100%;

    @include media('>=tablet') {
      max-width: 200px;
    }
  }

  &__categories {
    @include media('>=tablet') {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
    }
  }

  &__category-tile {
    margin-bottom: 15px;

    @include media('>=tablet') {
      width: calc(50% - 10px);
      margin-bottom: 20px;
    }
  }

  > .hero-slider {
    margin-bottom: 40px;
  }
}
