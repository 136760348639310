.product-order-position {
  $root: &;

  display: flex;
  justify-content: space-between;
  position: relative;

  &__cell {
    @include media('>=tablet') {
      padding: 0 .5rem;
    }

    &__image {
      padding-left: 0;
      width: 20%;
    }

    &__info {
      position: relative;

      @include media('>=laptop') {
        width: 80%;
      }
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
  }

  &__description {
    display: block;
    cursor: default;
    color: $grey-900;
    text-decoration: none;
    margin-bottom: 5px;

    &:hover,
    &:visited {
      color: $grey-900;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__wrapper {
    @include media('<laptop') {
      display: flex;

      &__image {
        padding-right: .5rem;
        width: 20%;
      }

      &__info {
        width: 80%;
      }
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin-top: -.3rem;

    .product-order-position__headline {
      width: 70%;
    }
  }

  &__summary {
    padding-right: 0;
    text-align: right;
  }

  &__details {
    &--value {
      &::before{
        content: "\0020";
      }
    }
  }

  &__delivery-information {
    color: $signalGreenDark;
    margin-top: .5rem;
    padding-bottom: .5rem;

    @include iconFont(delivery, before, true) {
      vertical-align: bottom;
      margin-right: .25rem;
    }
  }

  &__actions {
    display: flex;
    width: 100%;
    padding-top: 1rem;

    & > * {
      flex: 1;
    }

    .product-order-position__showMore {
      justify-content: end;
    }

    @include media('>laptop') {
      bottom: 0;
      padding-top: 0;
    }
  }

  &__action {
    color: $grey-900;

    &__delete {
      display: flex;
      align-items: center;
      cursor: pointer;

      @include iconFont(trash) {
        margin-right: .25rem;
      }
    }
  }

  &__price {
    color: $grey-900;
    display: block;
  }

  &__number {
    margin-bottom: .5rem;
    color: $grey-500;
  }

  &__deleted {
    padding: 1rem;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background: $black;

    &--message {
      color: $white;
      display: flex;
      align-items: center;

      p {
        margin: 0;
      }

      @include iconFont(trash, before) {
        color: $white;
        font-size: 1.5rem;
        margin-right: .75rem;
      }
    }

    &--revert {
      color: $grey-200;
      cursor: pointer;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      transition: color .2s;

      @include iconFont(loading) {
        color: $grey-200;
        margin-right: .5rem;
        transition: color .2s;
      }

      &:hover {
        color: $white;

        &::before {
          color: $white;
        }
      }
    }
  }

  &__reservedFor {
    color: $signalGreenDark;
  }

  &__showMore {
    align-items: center;
    cursor: pointer;
    display: flex;

    &--counter {
      color: $grey-500;
      padding-left: .5rem;
    }
  }

  .available-parts__damage-model {
    @include media('>laptop') {
      padding-bottom: 1rem;
    }
  }
}

.bundle-parts {
  @include media('>laptop') {
    padding-bottom: 1rem;
  }

  &__part {
    align-items: center;
    border-top: 1px solid $grey-200;
    display: flex;
    padding: 1rem 0;

    &__image {
      padding-right: .5rem;
      width: 20%;

      img {
        display: block;
        height: auto;
        width: 100%;
      }
    }

    &__content {
      width: 80%;
    }
  }
}

.part-gallery {
  span:only-child {
    max-width: 100%;
  }

  &__image {
    width: 100%;
  }
}
