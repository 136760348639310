.tag {
  $root: &;

  padding: .25rem .75rem;
  color: $black;
  border: 1px solid $black;
  display: inline-flex;
  align-items: center;
  transition: box-shadow .2s;
  text-decoration: none;
  gap: .25rem;

  &:hover {
    text-decoration: none;
    border-color: $black;

    #{$root}__button {
      color: $brandPrimaryColor;
    }
  }

  &:visited {
    color: $black;
  }

  &--button {
    padding-right: .5rem;
    cursor: pointer;
  }

  &--button-left {
    border: none;

    &--mobile-active {
      border: 1px solid $black;
    }

    #{$root}__button {
      right: auto;
      left: .25rem;
    }
  }

  &__button {
    transition: color .2s;
    cursor: pointer;
    display: flex;

    &::before {
      font-size: 1.5rem;
    }

    &--icon {
      &_close {
        @include iconFont(close);

        color: $black;
      }

      &_close-small {
        @include iconFont(close);

        color: $black;
      }
    }
  }
}
