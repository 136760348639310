.user-shortcut {
  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    text-align: center;
    font-style: normal;
    position: relative;
    color: $grey-900;

    &::before {
      font-size: 1.5rem;
      color: $brandPrimaryColor;
    }

    &--login {
      @include iconFont(user);
    }

    &--wishlist {
      @include iconFont(heart);
    }

    &--cart {
      @include iconFont(cart);
    }

    &--active {
      color: $brandPrimaryColor;
    }
  }

  &__label {
    display: none;

    @include media('>=tablet') {
      display: block;
      font-size: $fontSizeSmall;
      margin-top: .25rem;
      color: $black;
    }
  }

  &__notification-badge {
    position: absolute;
    font-size: .75rem;
    background-color: $progressiveRed;
    color: $white;
    min-width: 1.125rem;
    height: 1.125rem;
    line-height: 1.125rem;
    border-radius: 50%;
    text-align: center;
    padding: 0 .125rem;
    top: -.5rem;
    right: calc(50% - 1.5rem);

    &--active {
      background-color: $brandPrimaryColor;
      color: $white;
    }
  }

  &__flyout {
    width: calc(100vw - 30px);
    max-width: 320px;
    position: absolute;
    background-color: $white;
    border-radius: 5px;
    top: 100%;
    box-shadow: 0 0 5px rgb(0 0 0 / 30%);
    z-index: $indexBasketFlyout;
    right: 0;
    display: block;

    @include media('<tablet') {
      display: none;
    }

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      box-shadow: 0 0 1px rgb(0 0 0 / 30%);
      top: -5px;
      transform: rotate(45deg);
      display: block;
      position: absolute;
      z-index: 1;
      background: $white;
    }

    &::after {
      content: '';
      width: 20px;
      height: 7px;
      top: 0;
      display: block;
      position: absolute;
      z-index: 2;
      background: $white;
    }

    &--b2b {
      &::before {
        right: 17px;

        @include media('>=tablet') {
          right: 25px;
        }
      }
    }

    &--cart {
      &::before {
        right: 17px;

        @include media('>=tablet') {
          right: 25px;
        }
      }

      &::after {
        right: 12px;

        @include media('>=tablet') {
          right: 20px;
        }
      }
    }

    &--cart--b2b {
      &::before {
        right: 50px;

        @include media('>=tablet') {
          right: 115px;
        }
      }
    }

    &--wishlist {
      &::before {
        right: 50px;

        @include media('>=tablet') {
          right: 115px;
        }
      }

      &::after {
        right: 45px;

        @include media('>=tablet') {
          right: 100px;
        }
      }
    }

    &--wishlist--b2b {
      &::before {
        right: 83px;

        @include media('>=tablet') {
          right: 205px;
        }
      }
    }
  }
}
