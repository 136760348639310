.header {
  position: relative;
  z-index: $indexHeader;

  &__unset {
    z-index: unset;
  }

  &__top-bar {
    position: relative;
    z-index: $indexHeader + 1;
    border-bottom: 1px solid $grey-200;
    background: $white;
  }

  & &__meta-menu {
    display: none;

    @include media('>=laptop') {
      @include container;

      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__sticky {
    background: $white;

    &--active {
      box-shadow: 0 0 1rem 0 rgb(0 0 0 / 10%);
      will-change: position;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  &__main {
    @include container;

    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: .5rem 1rem;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: .5rem;

    @include media('>=laptop') {
      padding-bottom: 0;
      padding-top: 2rem;
      grid-column-gap: 2rem;
    }

    .select-car-model-flyout {
      grid-column: 1 / 3;
      display: none;

      @include media('>=tablet') {
        display: flex;
      }

      &-mobile {
        display: flex;

        @include media('>=laptop') {
          display: none;
        }
      }
    }

    &--type {
      &_checkout {
        @include media('>=laptop') {
          padding-bottom: 20px;
        }
      }

      &_not-logged-in {
        @include media('>=laptop') {
          padding-bottom: 2rem;
        }
      }
    }
  }

  &__logo-link {
    display: flex;
    height: auto;
    max-width: 16rem;
    margin: auto;
    justify-content: space-around;

    @include media('>=phone-lg') {
      margin: 0;
    }

    @include media('>=laptop') {
      max-width: 20rem;
    }
  }

  &__logo {
    height: auto;
    width: 100%;

    &--mobile {
      width: 4.5rem
    }
  }

  &__burger-menu-trigger {
    display: flex;
    text-align: center;
    color: $brandPrimaryColor;

    @include iconFont(burgerMenu) {
      font-size: 2rem;
    }

    @include media('>=laptop') {
      display: none;
    }
  }

  &__user-shortcuts {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 2rem;

    @include media('>=laptop') {
      order: 5;
    }
  }

  &__user-shortcut {
    &--login,
    &--wishlist {
      @include media('<laptop') {
        display: none;
      }
    }
  }

  &__navigation {
    display: none;

    @include media('>=laptop') {
      order: 6;
      display: block;
      width: 100%;
      grid-column: 1 / 4;
    }
  }

  &__search {
    grid-column: 3;

    &--active {
      width: 100%;
      grid-column: 1 / 4;
    }

    @include media('>=laptop') {
      grid-column: 2;
    }
  }

  &__search-autosuggest {
    margin: 0 auto;

    @include media('<laptop') {
      .input {
        &__wrapper {
          border: none;
        }

        &__field {
          display: none;
          padding: .5rem 0;
          transition: opacity 1s ease-out;
        }

        &__button {
          padding: .5rem 0;
        }

        &__close-icon {
          display: none;
          transition: opacity 1s ease-out;
        }
      }

      .search-autosuggest__input--active {
        .input {
          &__wrapper {
            border-bottom: 1px solid $black;
          }

          &__field {
            display: block;
          }

          &__close-icon {
            display: block;
          }

          &__button {
            padding-right: .5rem;
          }
        }
      }
    }

    @include media('>=phone-lg') {
      max-width: 25rem;
    }
  }

  &__product-flyout {
    width: calc(100vw - 30px);
    max-width: 320px;
    position: absolute;
    background: $white;
    border-radius: 5px;
    top: 100%;
    margin-top: 10px;
    right: 0;
    padding: 20px;
    box-shadow: 0 0 5px rgb(0 0 0 / 30%);
    z-index: $indexBasketFlyout;
  }

  &__checkout-process-breadcrumbs {
    order: 6;
  }
}
