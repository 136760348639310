.select-car-model-flyout {
  cursor: pointer;
  padding: .5rem 0;
  white-space: nowrap;
  display: flex;
  align-items: center;

  @include iconFont(car-search, before) {
    font-size: 1.5rem;
    margin-right: .5rem;
  }

  @include media('>=laptop') {
    border-right: 1px solid $grey-100;
    margin: 0 2rem 0 0;
    padding: 0 2rem 0 0;
  }

  &__modal {
    cursor: default;
  }

  &__label {
    font-size: .75rem;
    font-weight: 400;

    @include media('>=tablet') {
      font-size: 1rem;
    }
  }
}
