.go-top {
  position: fixed;
  bottom: 10px;
  left: 50%;
  margin-left: -25px;
  visibility: hidden;
  padding: 5px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  background: $grey-100;
  z-index: 99;
  margin-bottom: -60px;
  transition: margin-bottom .2s ease-out;
  text-decoration: none;
  text-align: center;

  @include media('>=tablet') {
    position: sticky;
    visibility: visible;
    top: calc(100% - 70px);
    bottom: auto;
    margin-bottom: 0;
    float: right;
  }

  &--sticky {
    visibility: visible;
    margin-bottom: 0;
  }

  @include iconFont(arrowRight) {
    font-size: $fontSizeXXMedium;
    color: $white;
    transition: color .2s;
    transform: rotateZ(-90deg);
    text-align: center;
  }
}
