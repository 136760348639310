.sidebar-menu {
  &__list {
    @include clearList;
  }

  &__item {
    display: block;
    margin-bottom: 1rem;
    text-decoration: none;

    &--active {
      text-decoration: underline;
    }

    &--mobile-hidden {
      display: none;

      @include media('>=tablet') {
        display: block;
      }
    }

    @include media('<=tablet') {
      border-bottom: 1px solid $grey-100;
      padding: 1.5rem;
      margin: 0;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  &__link {
    display: block;
    color: $black;
    text-decoration: none;
    position: relative;
    padding-left: 2rem;

    &:visited {
      color: $black;
    }

    &:hover,
    &--active {
      color: $brandPrimaryColor;

      &:visited {
        color: $brandPrimaryColor;
      }
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      font-size: $fontSizeXXMedium;
      text-decoration: none;
    }

    &.order-history {
      @include iconFont(shipping, before, true);
    }

    &.personal-data {
      @include iconFont(user);
    }

    &.logout {
      @include iconFont(logout, before);
    }
  }

  &__link-addition {
    color: $grey-500;
    font-weight: $fontWeightThin;
    margin-left: 20px;
  }
}
