.text-box {
  &__headline {
    font-size: $fontSizeMedium;
    color: $grey-900;
    margin-bottom: 15px;
  }

  &__paragraph {
    font-size: $baseFontSize;
    color: $grey-600;
    line-height: 1.3;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
