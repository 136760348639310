.placeholder-teaser {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  background: #ddd;

  &__text {
    text-transform: uppercase;
  }
}
