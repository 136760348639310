.product-box {
  height: 100%;

  &__images-container {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    /* stylelint-disable-next-line */
    aspect-ratio: 4 / 3;
  }

  &__images-wrapper {
    transform: translate3d(0, 0, 0);
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    transition-property: transform;
  }

  &__label {
    display: block;
    margin-bottom: 5px;
  }

  &__image {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    flex-shrink: 0;
  }
}

.product-box-tiles {
  $root: &;

  background: $white;
  position: relative;
  z-index: 1;
  height: 100%;
  transition: opacity ease .15s;

  &:hover,
  &:focus,
  &:active {
    opacity: .7;
  }

  &__slider {
    position: relative;
    z-index: 1;
  }

  &__slider-button {
    cursor: pointer;
    font-size: 20px;
    color: $grey-900;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include media('>laptop') {
      transition: transform .2s, color .2s, visibility .2s, opacity .2s;

      &:hover {
        transform: translateY(-50%) scale(1.1);
        color: $brandPrimaryColor;
      }
    }

    &--prev {
      left: 0;

      @include iconFont(arrowLeft);
    }

    &--next {
      right: 0;

      @include iconFont(arrowRight);
    }

    &--hidden,
    &--disabled {
      display: none;
    }
  }

  &__main-info {
    line-height: 1.2;
    text-decoration: none;
    color: inherit;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;

    @include media('>tablet') {
      overflow: initial;
    }

    &:hover,
    &:visited {
      text-decoration: none;
      color: inherit;
    }
  }

  &__name {
    color: $black;
    box-sizing: content-box;
    padding-top: 5px;
    height: 2.5rem;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__brand {
    font-size: $fontSizeSmall;
    color: $black;
    font-weight: $fontWeightSemiBold;
    text-transform: uppercase;
    display: block;
    margin-top: 5px;
  }

  &__utils {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__price {
    padding: .5rem 0;
    display: inline-block;
    text-align: left;
    color: $grey-900;
    white-space: nowrap;
    position: relative;

    &--promo {
      color: $brandPrimaryColor;

      &::after {
        content: "\00a0";
      }

      #{$root}__price {
        padding: 0;
      }
    }

    &--old {
      text-decoration: line-through;
      border: none;
      color: $grey-500;
      margin-left: 5px;

      @include media('<tablet') {
        padding: 0;
        margin-left: 0;
        font-size: $baseFontSize;
      }
    }
  }

  &__in-selected-model-text {
    color: $signalRedDark;
  }

  &__wishlist {
    @include wishlistHeart {
      width: 35px;
      height: 35px;
      border: 0;
      margin-top: 5px;
      cursor: pointer;
      font-weight: 700;
      color: $brandPrimaryColor;
      font-size: 0;
      display: block;
    }
  }

  &__labels {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &__placeholder {
    &--image {
      width: 100%;
      height: 200px;
      position: relative;

      @include iconFont(camera) {
        font-size: 30px;
        color: $grey-600;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &--brand {
      @include componentPreloader {
        display: block;
        width: 40%;
        height: 14px;
        margin-bottom: 2px;
      }
    }

    &--name {
      @include componentPreloader {
        display: block;
        width: 100%;
        height: 29px;
      }
    }
  }
}

.product-box-list {
  $root: &;

  display: flex;
  flex-direction: row;
  width: 100%;
  margin-right: 20px;

  &:hover {
    box-shadow: 7px 0 30px -10px #888;
  }

  &__link-product {
    text-decoration: none;
    color: $black;

    @include media('>tablet') {
      pointer-events: none;
    }
  }

  &__slider {
    width: 50%;

    @include media('>tablet') {
      position: relative;
      z-index: 0;
      width: 30%;
    }
  }

  &__labels {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;

    @include media('<tablet') {
      position: relative;
      top: 15%;
      width: 35%;
    }
  }

  &__main {
    width: 50%;
    margin-left: 20px;

    @include media('>tablet') {
      display: flex;
      flex-direction: row;
      width: 70%;
    }

    &--info {
      display: block;
      margin-top: 20px;
      line-height: 1.2;
      text-decoration: none;
      color: inherit;
      overflow: hidden;
      position: relative;

      @include media('>tablet') {
        margin-top: 30px;
        overflow: initial;
        width: 70%;
      }

      &:hover,
      &:visited {
        text-decoration: none;
        color: inherit;
      }
    }

    &--name {
      color: $grey-600;
      display: block;
      box-sizing: content-box;
      padding-top: 5px;
      position: relative;
      overflow: hidden;
      text-decoration: none;
      font-size: 15px;
      font-weight: 500;

      /* stylelint-disable */
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      /* stylelint-enable */

      @include media('>tablet') {
        display: block;
        font-weight: 600;
        font-size: 20px;
      }
    }

    &--brand {
      color: $black;
      font-weight: $fontWeightSemiBold;
      text-transform: uppercase;
      display: block;
      margin-top: 10px;

      @include media('>tablet') {
        font-size: 16px;
      }
    }
  }

  &__product-details {
    display: none;
    width: 90%;

    @include media('>tablet') {
      display: block;
    }
  }

  &__salebox {
    @include media('>tablet') {
      width: 30%;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    &__wishlist-mobile {
      display: flex;
      justify-content: flex-end;

      @include media('>=tablet') {
        justify-content: flex-start;
      }
    }

    &__button-positioning {
      width: 50%;

      @include media('<tablet') {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
    }

    &__prices {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 20px;

      @include media('<tablet') {
        align-items: flex-start;
      }
    }

    &__price-current {
      font-size: 20px;
      font-weight: 600;
    }

    &__utils {
      margin-bottom: 30px;
    }

    &__availability {
      display: none;

      @include media('>tablet') {
        margin-bottom: 5px;
        display: block;
        color: $signalGreenDark;
      }
    }

    &__button-cart {
      position: absolute;
      border: 0;
      margin: 2px 10px 0 0;
      cursor: pointer;
      border-radius: 20px;
      color: $brandPrimaryColor;
      display: none;
      bottom: 31px;
      right: 0;

      @include media('>=tablet') {
        display: block;
        background: none;
      }

      &__in-cart {
        @include iconFont(cart-full) {
          font-size: $fontSizeXXXMedium;
          transition: color .15s linear;
          font-weight: $fontWeightRegular;
          color: $brandPrimaryColor;
        }
      }
    }

    &__button-wishlist {
      @include wishlistHeart {
        width: 35px;
        height: 35px;
        border: 0;
        margin-top: 5px;
        cursor: pointer;
        font-weight: 700;
        color: $brandPrimaryColor;
        font-size: 0;
        display: block;
      }
    }

    &__button-to-article {
      display: none;

      @include media('>tablet') {
        position: relative;
        width: 65%;
        height: 35px;
        font-size: 15px;
        font-weight: $fontWeightBold;
        background-color: $brandPrimaryColor;
        color: $white;
        margin: 5px 10px 0;
        display: block;
        cursor: pointer;
        text-decoration: none;
        text-align: center;
        line-height: 32px;
      }
    }
  }

  &__price {
    padding: 5px 0;
    display: inline-block;
    text-align: left;
    color: $black;
    white-space: nowrap;
    position: relative;
    font-size: 20px;
    font-weight: $fontWeightMedium;

    @include media('>tablet') {
      font-size: 20px;
      font-weight: 600;
    }

    &--promo {
      color: $brandPrimaryColor;

      #{$root}__price {
        padding: 0;
      }
    }

    &--old {
      text-decoration: line-through;
      border: none;
      color: $grey-500;
      margin-bottom: 5px;
      font-size: 18px;
      font-weight: 500;

      @include media('<tablet') {
        padding: 0;
        font-size: $baseFontSize;
      }
    }
  }

  &__variation {
    background: white;
    height: 100%;
    position: absolute;
    z-index: 3;
    bottom: 0;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 7px 0 30px -10px #888;
    visibility: hidden;

    &__wrapper {
      margin-top: 5px;
    }
  }
}

.product-box-list:hover .product-box-list__variation {
  @include media('>tablet') {
    visibility: visible;
  }
}

.product-box-list:hover .product-box-list__labels {
  z-index: 2;
}

.energy-class {
  position: relative;
  width: 155px;
  font-family: sans-serif;
  margin-left: 5px;
  color: white;
  text-decoration: none;
  text-shadow: 2px 2px 4px #000;
}

.energy-class div {
  position: relative;
  height: 20px;
}

.energy-class div::before {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  background: transparent;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.energy-class div::after {
  position: absolute;
  top: 0;
  right: 30px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  text-shadow: 0 0 2px #000;
  text-transform: uppercase;
}

.energy-class div.energy-dark-green {
  padding-left: 10px;
  padding-top: 2px;
  background: #009640;
  width: 40px;
}

.energy-class div.energy-dark-green::before {
  border-left: 8px solid #009640;
}

.energy-class div.energy-dark-green::after {
  padding-left: 10px;
}

.energy-class div.energy-medium-green {
  padding-left: 10px;
  padding-top: 2px;
  background: #50af31;
  width: 40px;
}

.energy-class div.energy-medium-green::before {
  border-left: 8px solid #50af31;
}

.energy-class div.energy-medium-green::after {
  padding-left: 10px;
}

.energy-class div.energy-green {
  padding-left: 10px;
  padding-top: 2px;
  background: #c7d301;
  width: 40px;
}

.energy-class div.energy-green::before {
  border-left: 8px solid #c7d301;
}

.energy-class div.energy-green::after {
  padding-left: 10px;
}

.energy-class div.energy-yellow {
  padding-left: 10px;
  padding-top: 2px;
  background: #ffed00;
  width: 40px;
}

.energy-class div.energy-yellow::before {
  border-left: 8px solid #ffed00;
}

.energy-class div.energy-yellow::after {
  padding-left: 10px;
}

.energy-class div.energy-light-orange {
  padding-left: 10px;
  padding-top: 2px;
  background: #fbb900;
  width: 40px;
}

.energy-class div.energy-light-orange::before {
  border-left: 8px solid #fbb900;
}

.energy-class div.energy-light-orange::after {
  padding-left: 10px;
}

.energy-class div.energy-dark-orange {
  padding-left: 10px;
  padding-top: 2px;
  background: #ec6707;
  width: 40px;
}

.energy-class div.energy-dark-orange::before {
  border-left: 8px solid #ec6707;
}

.energy-class div.energy-dark-orange::after {
  padding-left: 10px;
}

.energy-class div.energy-red {
  padding-left: 10px;
  padding-top: 2px;
  background: #e30613;
  width: 40px;
}

.energy-class div.energy-red::before {
  border-left: 8px solid #e30613;
}

.energy-class div.energy-red::after {
  padding-left: 10px;
}
