@mixin wishlistHeart() {
  text-decoration: none;
  position: relative;

  @content;

  &__on-wishlist {
    @include iconFont(heart-full, before);
    @include wishlistHeartIcon;
  }

  &__not-on-wishlist {
    @include iconFont(heart);
    @include wishlistHeartIcon;
  }
}

@mixin wishlistHeartIcon {
  font-size: $fontSizeXXMedium;
  transition: color .15s linear;
  font-weight: $fontWeightSemiBold;
  color: $brandPrimaryColor;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
