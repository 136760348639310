@mixin container($containerWidth: 1514px) {
  width: 100%;
  max-width: $containerWidth;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;

  @include media('>=tablet') {
    flex-wrap: nowrap;
  }

  @include media('>=laptop') {
    flex-wrap: wrap;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
