.product-page-brand {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;

  &__logo {
    width: auto;
    height: auto;
    max-width: 100px;
    max-height: 30px;
    color: $white;
    display: block;

    @include media('>laptop') {
      max-height: 60px;
    }
  }

  &__link {
    display: block;

    &--disabled {
      pointer-events: none;
      cursor: default;
    }
  }
}
