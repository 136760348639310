.brands-teaser {
  $root: &;

  &__additional-brands {
    margin-top: 50px;
  }

  /* // PLACEHOLDER */

  /* stylelint-disable-next-line */
  carousel,
  [is="carousel"] {
    .brand-tile {
      display: none;

      &:nth-child(-n+2) {
        display: block;
      }

      @include media('>=tablet') {
        &:nth-child(-n+5) {
          display: block;
        }
      }

      @include media('>laptop') {
        &:nth-child(-n+7) {
          display: block;
        }
      }
    }
  }
}
