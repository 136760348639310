.product-detailed-page {
  @include container;

  &--seo {
    display: none;
  }

  &__top {
    display: flex;
    flex-flow: column wrap;
    gap: 1rem;
    margin-top: 1rem;

    @include media('>=tablet') {
      flex-flow: row nowrap;
      gap: 2rem;
    }
  }

  &__gallery {
    width: 100%;
    position: relative;

    @include media('>=tablet') {
      width: 300px;
      min-width: 300px;
    }

    @include media('>=laptop') {
      width: 400px;
      min-width: 400px;
    }
  }

  &__sale-box {
    width: 100%;

    @include media('>=tablet') {
      margin-left: auto;
    }
  }

  &__available-parts {
    padding: 4rem 0;
  }

  &__details {
    width: 100%;
    padding: 20px 0;
  }

  &__section {
    width: 100%;
    position: relative;
    padding-top: 30px;
    border-bottom: 1px solid $grey-200;

    &:last-child {
      border-bottom: 0;
    }

    &:empty {
      display: none;
    }
  }

  &__carousel {
    padding: 20px 0;
  }
}
