.hero-slider {
  $root: &;

  position: relative;

  &__wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    z-index: 1;
  }

  &__container {
    transform: translate3d(0, 0, 0);
    position: relative;
    width: 100%;
    display: flex;
    transition-property: transform;
  }

  &__slide {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    flex-shrink: 0;
    z-index: 2;
    width: 100%;
  }

  &__slide-content {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 40%;
    text-decoration: none;

    &--text-dark {
      color: $grey-900;
    }

    &--text-bright {
      color: $white;
    }
  }

  &__button {
    cursor: pointer;
    display: none;
    text-decoration: none;
    color: $white;

    &:visited {
      color: $white;
    }

    &:hover {
      color: $brandPrimaryColor;
    }

    &--prev {
      @include iconFont(arrowLeft) {
        font-size: 35px;
      }
    }

    &--next {
      @include iconFont(arrowRight) {
        font-size: 35px;
      }
    }

    @include media('>=tablet') {
      position: absolute;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      z-index: 4;
      margin-top: -23px;
      cursor: pointer;

      &--prev {
        left: 15px;
      }

      &--next {
        right: 15px;
      }

      &.button-disabled {
        display: none;
      }
    }
  }

  &__pagination {
    margin-top: 1px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  &__pagination-button {
    cursor: pointer;
    display: block;
    flex: 1 1 0;
    margin-right: 1px;
    height: 2px;
    font-size: 0;
    position: relative;
    transition: color .2s;

    &:last-child {
      margin-right: 0;
    }

    &::before {
      content: '';
      background: $grey-200;
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      transition: background .2s;
    }

    @include media('>=tablet') {
      padding: 15px 25px 17px 0;
      height: auto;
      text-align: left;
      font-size: $baseFontSize;
      font-weight: $fontWeightSemiBold;
      color: $grey-200;
      text-decoration: none;
      margin-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:visited,
      &:hover {
        color: $grey-200;
      }
    }

    &--active {
      color: $black;

      &::before {
        background: $black;
      }

      #{$root}__pagination-progress-bar {
        width: 100%;
        transition-property: width;
      }

      @include media('>=tablet') {
        &:visited,
        &:hover {
          color: $black;
        }
      }
    }
  }

  &__pagination-progress-bar {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0;
    background: $brandPrimaryColor;
    transition-property: none;
  }

  &__pagination-count {
    display: inline-block;
    margin-right: 10px;
  }

  &__headline {
    position: relative;
    top: 50px;
    left: 60px;
    font-weight: $fontWeightBold;
    font-size: $fontSizeXXXLarge;
    width: 60%;
    line-height: 1.3;

    @include media('<=tablet') {
      font-size: $fontSizeXLarge;
      width: 60%;
      top: 20px;
      margin: 0;
      left: 30px;
    }

    @include media('<=phone-lg') {
      font-size: $fontSizeXXXMedium;
      left: 20px;
    }

    @include media('<=phone') {
      top: 10px;
      left: 20px;
      font-size: $fontSizeXXMedium;
    }
  }

  &__subheadline {
    position: relative;
    left: 60px;
    font-size: $fontSizeXLarge;
    margin-top: 80px;
    width: 60%;

    @include media('<=tablet') {
      font-size: $fontSizeLarge;
      width: 60%;
      margin-top: 35px;
      left: 30px;
    }

    @include media('<=phone-lg') {
      margin-top: 25px;
      font-size: $fontSizeXXMedium;
      left: 20px;
    }

    @include media('<=phone') {
      margin-top: 15px;
      left: 20px;
      font-size: $fontSizeMedium;
    }
  }
}

/* // PLACEHOLDER */

/* stylelint-disable-next-line */
hero-slider,
[is="hero-slider"] {
  .hero-slider {
    &__placeholder {
      &--image {
        @include componentPreloader {
          width: 100%;
          height: 0;
          padding-bottom: 40%;
        }
      }

      &--pagination {
        /* stylelint-disable-next-line */
        @extend .hero-slider__pagination;
      }

      &--pagination-button {
        /* stylelint-disable-next-line */
        @extend .hero-slider__pagination-button;
      }
    }
  }
}
