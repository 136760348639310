@mixin checkoutLayout() {
  @include container;

  padding-top: 50px;
  padding-bottom: 50px;

  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @include media('>=tablet') {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    @include media('>=tablet') {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
  }

  &__headline {
    @include fontSize($fontSizeLarge);

    font-weight: $fontWeightSemiBold;
  }

  &__submit-button {
    &--top {
      display: none;

      @include media('>=tablet') {
        display: inline-flex;
        width: auto;
      }
    }

    &--bottom {
      width: 100%;
      order: 1;

      @include media('>=tablet') {
        width: auto;
        order: 2;
      }
    }
  }

  &__back-button {
    width: 100%;
    order: 2;
    margin-top: 20px;

    @include media('>=tablet') {
      width: auto;
      order: 1;
      margin-top: 0;
    }
  }
}
