.carousel {
  position: relative;

  &__wrapper {
    width: 100%;
    margin: 0;
    overflow: hidden;
  }

  &__container {
    transform: translate3d(0, 0, 0);
    position: relative;
    width: 100%;
    display: flex;
    transition-property: transform;
    align-items: stretch;
  }

  &__slide {
    position: relative;
    flex-shrink: 0;
    z-index: 2;
    width: 21%;
  }

  &__button {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-decoration: none;
    color: $brandPrimaryColor;
    font-size: 20px;
    z-index: 10;

    @include media(">laptop") {
      &:hover {
        color: $brandPrimaryColor;
      }
    }

    &--prev {
      left: .5rem;

      @include iconFont(arrowLeft) {
        font-size: $fontSizeXXLarge;
        background: rgb(255 255 255 / 40%);
      }
    }

    @include media('<=tablet') {
      display: none;
    }

    &--next {
      right: .5rem;

      @include iconFont(arrowRight) {
        font-size: $fontSizeXXLarge;
        background: rgba(255 255 255 / 40%);
      }
    }

    &--hidden {
      display: none;
    }
  }

  &__pagination {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  &__pagination-bullet {
    display: block;
    background: $grey-900;
    margin: 0 2px;
    height: 3px;
    width: 20px;

    &--current {
      background: $brandPrimaryColor;
    }
  }
}

/* // PLACEHOLDER */

/* stylelint-disable-next-line */
carousel,
[is="carousel"] {
  display: flex;
  width: calc(100% - 40px);
  margin: 0 auto;
}
