.empty-search-info {
  @include container;

  padding-bottom: 1.25rem;

  &__headline {
    font-size: $fontSizeXXMedium;
    padding-bottom: 2rem;
    position: relative;
  }

  &__searchquery {
    font-weight: $fontWeightBold;

    &::before {
      content: '"';
    }

    &::after {
      content: '"';
    }
  }

  &__paragraph {
    font-size: $baseFontSize;
    color: $grey-600;
    padding-bottom: 2rem;
  }

  &__last-search-terms {
    padding-bottom: 2rem;
  }
}
