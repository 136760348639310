@use "sass:math";

$columnTypes: lg, md, sm, xs;
$columnCount: 12;

/* // add more teasers if margin needed */
.recommendations-teaser,
.html-teaser,
.services-list-teaser {
  margin-bottom: 3rem;
}

.grid {
  @include container;

  padding-top: 2rem;

  .row {
    margin-left: -.25rem;
    margin-right: -.25rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    &:empty {
      display: none;
    }

    .grid {
      padding: 0;
    }
  }

  @each $prefix in $columnTypes {
    .col-#{$prefix} {
      @for $i from 1 through $columnCount {
        &-#{$i} {
          width: 100%;
          padding: 0 .25rem 1rem;

          @include media('>=tablet') {
            width: calc(100% / #{math.div($columnCount, $i)});
          }

          &.nopadding {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}
