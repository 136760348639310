.form-structure {
  &__structure {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__row {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;

    @include media('>=tablet') {
      margin-bottom: 20px;
    }
  }

  &__row-left-column {
    display: none;

    @include media('>=tablet') {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }

  &__row-inputs {
    width: calc(100% + 20px);
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -10px;

    &--column {
      @include media('>=tablet') {
        width: calc(70%);
      }
    }
  }

  &__input-wrapper {
    padding: 0 10px;
    width: 100%;
    margin-bottom: 20px;

    @include media('>=tablet') {
      margin-bottom: 0;
    }

    &--no-label {
      @include media('>=tablet') {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &--size {
      &_100 {
        width: 100%;
      }

      &_75 {
        width: 75%;
      }

      &_60 {
        width: 60%;
      }

      &_50 {
        width: 50%;
      }

      &_40 {
        width: 40%;
      }

      &_25 {
        width: 25%;
      }
    }

    &--size-tablet {
      &_100 {
        @include media('>=tablet') {
          width: 100%;
        }
      }

      &_75 {
        @include media('>=tablet') {
          width: 75%;
        }
      }

      &_60 {
        @include media('>=tablet') {
          width: 60%;
        }
      }

      &_50 {
        @include media('>=tablet') {
          width: 50%;
        }
      }

      &_40 {
        @include media('>=tablet') {
          width: 40%;
        }
      }

      &_25 {
        @include media('>=tablet') {
          width: 25%;
        }
      }
    }

    &--size-desktop {
      &_100 {
        @include media('>laptop') {
          width: 100%;
        }
      }

      &_75 {
        @include media('>laptop') {
          width: 75%;
        }
      }

      &_60 {
        @include media('>laptop') {
          width: 60%;
        }
      }

      &_50 {
        @include media('>laptop') {
          width: 50%;
        }
      }

      &_40 {
        @include media('>laptop') {
          width: 40%;
        }
      }

      &_25 {
        @include media('>laptop') {
          width: 25%;
        }
      }
    }
  }

  &__input {
    width: 100%;
  }

  &__row-hint {
    margin-top: 5px;
    font-size: $fontSizeSmall;
    color: $grey-600;
  }

  &__row-label {
    color: $black;
    font-size: $fontSizeMedium;
    font-weight: $fontWeightSemiBold;
  }
}
