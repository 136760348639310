.search-autosuggest {
  &__wrapper {
    position: absolute;
    z-index: $indexSearchPageOverlay;
    box-shadow: 0 10px 10px -10px rgb(0 0 0 / 25%);
    left: 0;
    right: 0;
    background: $white;
    padding: 2rem 0;

    @include media('>=laptop') {
      margin-top: 1rem;
    }
  }

  &__overlay {
    position: fixed;
    inset: 120px 0 0;
    background: rgb(0 0 0 / 80%);
    display: block;
    opacity: 1;
    transition: visibility .3s, opacity .3s;
    z-index: -1;
  }

  &__input {
    width: 100%;
    position: relative;
    z-index: 2;
    padding-top: 0;
  }

  &__suggestions-grid {
    @include container;

    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 1rem;
    padding-bottom: 3rem;

    @include media('<tablet') {
      grid-template-columns: 1fr;
    }
  }

  &__suggestions-grid-category {
    grid-column: 1;
  }

  &__suggestions-grid-products {
    grid-column: 2;
    grid-row: 1 / 3;

    @include media('<tablet') {
      display: none;
    }
  }

  &__suggestions-grid-suggestion {
    grid-column: 1;
    grid-row: 1;
  }

  &__tiles {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }

  &__suggestions-button {
    @include container;

    display: flex;
    justify-content: end;

    @include media('<tablet') {
      justify-content: center;
      flex-direction: column;
    }
  }

  &__suggestions-list {
    margin-top: 1rem;
  }

  &__suggestion {
    display: block;
    margin-top: .5rem;
    position: relative;
    color: $black;
    text-decoration: none;

    &:visited {
      color: $black;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover,
    &--active {
      color: $brandPrimaryColor;
      text-shadow: 0 0 10px rgb(0 0 0 / 15%);

      &:visited {
        color: $brandPrimaryColor;
      }
    }
  }
}
