.box-insertion-teaser {
  height: 100%;

  &__box {
    display: flex;
    padding: 15px 0;
  }

  &__icon {
    width: 40px;
    height: 40px;
    font-size: $fontSizeXXLarge;
    display: block;

    @include media('>=laptop') {
      width: 55px;
      height: 55px;
      font-size: $fontSizeXXXLarge;
    }

    &--nm-icon-delivery-24h {
      @include iconFont(shipping, after);
    }

    &--nm-icon-gift-voucher {
      @include iconFont(gift, after);
    }

    &--nm-icon-shop-cart {
      @include iconFont(cart, after);
    }

    &--nm-icon-return {
      @include iconFont(return, after);
    }

    &--nm-icon-express-delivery {
      @include iconFont(time, after);
    }

    &--nm-icon-check {
      @include iconFont(checked, after);
    }
  }

  &__image-wrapper {
    position: relative;
    text-align: center;
    padding: 0 15px;
    display: flex;
    align-items: center;
  }

  &__image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
  }

  &__headline {
    font-size: $fontSizeLarge;
    font-weight: $fontWeightSemiBold;
    padding-bottom: 5px;

    @include media('>=tablet') {
      padding-bottom: 15px;
    }
  }

  &__theme {
    &--default {
      color: $grey-900;
    }

    &--color-brand-bg {
      background-color: $brandPrimaryColor;
      color: $white;
    }

    &--color-gray-dark-bg {
      background-color: $grey-700;
      color: $white;
    }

    &--color-gray-darker-bg {
      background-color: $grey-700;
      color: $white;
    }

    &--color-success-bg {
      background-color: $signalGreenLight;
      color: $white;
    }

    &--color-text-bg {
      background-color: $grey-900;
      color: $brandPrimaryColor;
    }

    &--color-warning-bg {
      background-color: lighten($signalRedLight, 30%);
      color: $white;
    }

    &--color-danger-bg {
      background-color: lighten($signalRedLight, 30%);
      color: $white;
    }

    &--color-info-bg {
      background-color: $signalInfo;
      color: $white;
    }

    &--color-white-bg {
      background-color: $white;
      color: $brandPrimaryColor;
    }

    &--color-black-bg {
      background-color: $grey-900;
      color: $brandPrimaryColor;
    }

    &--color-gray-bg {
      background-color: $grey-100;
      color: $brandPrimaryColor;
    }

    &--color-gray-lighter-bg {
      background-color: $grey-050;
      color: $brandPrimaryColor;
    }

    &--color-gray-light-bg {
      background-color: $grey-100;
      color: $brandPrimaryColor;
    }
  }

  &__text {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    font-size: $baseFontSize;
    font-weight: $fontWeightRegular;
    line-height: 1.4;
  }

  a {
    text-decoration: none;

    &:hover {
      box-shadow: 0 5px 5px -3px $grey-700;
    }
  }
}
