.services-list-teaser {
  $root: &;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;

  &--size {
    &_1 {
      #{$root}__service {
        width: 100%;
      }
    }

    &_2 {
      #{$root}__service {
        width: 50%;
      }
    }

    &_3 {
      #{$root}__service {
        width: 50%;

        @include media('>=tablet') {
          width: calc(100% / 3);
        }
      }
    }

    &_4 {
      #{$root}__service {
        width: 50%;

        @include media('>=tablet') {
          width: 25%;
        }
      }
    }

    &_5 {
      #{$root}__service {
        width: 50%;

        @include media('>=tablet') {
          width: 20%;
        }
      }
    }

    &_6 {
      #{$root}__service {
        width: 50%;

        @include media('>=tablet') {
          width: calc(100% / 3);
        }
      }
    }

    &_7 {
      #{$root}__service {
        width: 50%;

        @include media('>=tablet') {
          width: calc(100% / 3);
        }
      }
    }

    &_8 {
      #{$root}__service {
        width: 50%;

        @include media('>=tablet') {
          width: 25%;
        }
      }
    }

    &_9 {
      #{$root}__service {
        width: 50%;

        @include media('>=tablet') {
          width: 25%;
        }
      }
    }

    &_10 {
      #{$root}__service {
        width: 50%;

        @include media('>=tablet') {
          width: 20%;
        }
      }
    }

    &_11 {
      #{$root}__service {
        width: 50%;

        @include media('>=tablet') {
          width: 20%;
        }
      }
    }

    &_12 {
      #{$root}__service {
        width: 50%;

        @include media('>=tablet') {
          width: 25%;
        }
      }
    }
  }

  &__service {
    padding: 10px;
  }
}
