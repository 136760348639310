.navigation-side {
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }

  li {
    list-style: none;
    padding: 5px 0 5px 15px;
  }

  &--link {
    text-decoration: none;
    color: $black;
    font-weight: $fontWeightSemiBold;

    &:hover {
      color: $brandPrimaryColor;
    }

    &__arrow {
      margin-left: -20px;

      @include iconFont(arrowRight) {
        color: $brandPrimaryColor;
        font-size: 10px;
        display: inline-block;
        margin-right: 10px;
      }
    }

    &__active {
      color: $brandPrimaryColor;
    }
  }

  &__header {
    margin-bottom: 30px;

    @include media('>=tablet') {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__name {
    @include fontSize($fontSizeLarge);

    display: block;
    width: 100%;
    margin: 0 0 20px;
    padding: 0;
    font-weight: $fontWeightSemiBold;

    @include media('>=tablet') {
      margin: 0;
    }
  }
}
