.footer-teaser {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  &__accordion {
    > button {
      color: $whiteColor;
      padding-bottom: 0;

      @include media('<tablet') {
        font-weight: $fontWeightRegular;
        padding: 1rem;

        &::before,
        &::after {
          right: 1rem;
        }
      }
    }

    .accordion__content--mobile-only {
      @include media('<tablet') {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }

  &__column {
    display: inline-block;
    width: 100%;

    @include media('>=tablet') {
      width: 50%;
      padding-right: 20px;

      &:nth-child(2n) {
        padding-right: 0;
      }
    }

    @include media('>=laptop') {
      width: 25%;
      padding-bottom: 0;
      padding-right: 0;
    }
  }

  &__list {
    @include clearList;
  }

  &__list-item {
    color: $white;
  }

  &__link {
    padding: 5px 5px 5px 0;
    display: block;
    text-decoration: none;
    color: inherit;

    @include media('>=laptop') {
      display: inline-block;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &:active {
      color: inherit;
    }
  }
}
