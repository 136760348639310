.image-teaser {
  $root: &;

  display: block;
  text-decoration: none;
  width: 100%;
  height: 190px;
  background-size: cover;
  overflow: hidden;
  position: relative;
  color: $white;

  @include media('>=tablet') {
    height: 380px;
  }

  @include media('>laptop') {
    &:hover {
      #{$root}__image {
        transform: scale(1.2);
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    transition: transform 1s;
    z-index: 1;
  }

  &__headline {
    position: absolute;
    z-index: 2;
    font-size: $fontSizeXLarge;
    font-weight: $fontWeightBold;
    line-height: 1.3;
    text-decoration: none;
    inset: 20px;

    @include media('>=tablet') {
      max-width: 300px;
      inset: 30px auto auto 30px;
      font-size: $fontSizeXXXLarge;
    }
  }

  &__subheadline {
    display: none;

    @include media('>=tablet') {
      position: absolute;
      bottom: 30px;
      left: 30px;
      z-index: 2;
      display: block;
    }
  }

  &__overlay {
    @extend #{$root}__image;

    &--dark {
      color: $grey-900;
    }

    &--color {
      &-info {
        background-color: #39255e;
      }

      &-gray {
        background-color: #20252d;
      }
    }
  }
}
