$tileGap: 2rem;

.products-list {
  $root: &;

  &__not-in-selected-model-header-wrapper {
    width: 100%;
    display: flex;

    span {
      letter-spacing: -.03em;
      font-stretch: 130%;
    }
  }

  &__tiles {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    gap: $tileGap;

    &__element {
      &--type {
        &_product {
          width: calc((100% - (#{$tileGap} * 3)) / 4);

          @include media('<laptop-lg') {
            width: calc((100% - (#{$tileGap} * 2)) / 3);
          }

          @include media('<laptop') {
            width: calc((100% - #{$tileGap}) / 2);
          }

          @include media('<phone') {
            width: 100%;
          }
        }

        &_product-wishlist {
          @extend #{$root}__tiles__element--type_product;

          @include media('>tablet') {
            width: calc(100% / 4);
            border: none;
          }
        }

        &_insertion {
          width: 100%;
          padding: 20px 0;
          margin: 20px 0;
          border-top: 1px solid $grey-100;
          border-bottom: 1px solid $grey-100;
        }
      }

      &--size {
        &_25 {
          @include media('>tablet') {
            width: calc(100% / 4);
            border: none;
          }
        }
      }
    }
  }

  &__product-box {
    width: 100%;
    height: 100%;
  }

  &__list {
    width: 100%;

    &__element {
      &--type {
        &_product {
          border-bottom: 1px solid $grey-100;

          @include media('>tablet') {
            border: none;
          }
        }
      }
    }
  }
}

/* // PLACEHOLDER */

/* stylelint-disable-next-line */
/* products-list,
[is="products-list"] {
  @extend .products-list;

  .product-box--placeholder {
    @extend .products-list__element--type_product;
  }

  [slot] {
    @extend .products-list__element--type_insertion;
  }
} */
