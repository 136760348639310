.product-additional-information {
  &__headline {
    @include fontSize($fontSizeXMedium);

    width: 100%;
    padding: 15px 0;
    text-align: left;
    font-weight: $fontWeightRegular;
    margin: 0;
  }

  &__info {
    font-size: $baseFontSize;
    margin: 0;
    color: $grey-500;
    font-weight: $fontWeightRegular;
    line-height: 1.5;
    padding-bottom: 10px;

    &:last-child {
      padding-bottom: 0;
    }
  }
}
