/* // AUDI color scheme */
$black: #010101;
$white: #fff;
$progressiveRed: #f50537;
$signalRedDark: #eb0d3f;
$signalRedLight: #fd2c4e;
/*
// This dark green was made darker, than the audi ci recommends. This decision was made because the contrast on white background was too low. (Before 3.39:1, Now 4.56:1)
*/
$signalGreenDark: #0c890c;
$signalGreenLight: #15da15;
$signalYellow: #fa0;
$signalInfo: #5bc0de;
$grey-900: #1a1a1a;
$grey-800: #333;
$grey-700: #4c4c4c;
$grey-600: #666;
/*
// Grey-500 is the minimum color that is applicable for text (WCAG SC 1.4.3). On white background either use this or something darker.
*/
$grey-500: #757575;
$grey-400: #999;
$grey-300: #b3b3b3;
$grey-200: #ccc;
$grey-100: #d9d9d9;
$grey-050: #e5e5e5;
$grey-025: #f2f2f2;

/* // Colors */
$brandPrimaryColor: $black;
$brandAccentColor: $progressiveRed;
$whiteColor: $white;

@mixin gradientWhiteToTransparent() {
  background: white;
  background: linear-gradient(to right, rgb(255 255 255 / 100%) 0%, rgb(255 255 255 / 0%) 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
}

@mixin gradientTransparentToWhite() {
  background: transparent;
  background: linear-gradient(to right, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 100%) 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
}
