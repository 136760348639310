.address {
  position: relative;

  $root: &;

  &__line {
    display: block;
    font-size: $baseFontSize;
    color: $grey-600;
    font-style: normal;
    line-height: 1.3;

    &--main {
      color: $grey-900;
    }

    &--phone {
      @include iconFont(phone, before);

      &::before {
        margin-right: 5px;
      }
    }
  }

  &__button {
    background: none;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 5px 10px;
    position: absolute;
    right: 20px;

    &-text {
      visibility: hidden;
      color: $grey-500;
      font-size: $fontSizeSmall;
    }

    &--edit {
      top: 0;
      transition: border .2s linear, color .2s linear;

      @include iconFont(edit, after) {
        font-size: 18px;
        margin-left: 10px;
      }

      &:hover {
        border: 1px solid $grey-200;

        &::after {
          color: $brandPrimaryColor;
        }

        #{$root}__button-text {
          visibility: visible;
        }
      }
    }

    &--remove {
      top: 30px;
      transition: color .2s linear;

      @include iconFont(trash) {
        font-size: 18px;
      }

      &:hover {
        &::before {
          color: $grey-500;
        }
      }
    }
  }
}
