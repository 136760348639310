.global-app-error {
  display: flex;
  position: relative;
  padding-left: 40px;
  min-height: 30px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @include media('>=tablet') {
    padding-left: 70px;
    min-height: 50px;
  }

  @include iconFont(sadBrowser) {
    font-size: 30px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: $brandPrimaryColor;

    @include media('>=tablet') {
      font-size: 50px;
    }
  }

  &__error {
    margin-top: 20px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    background: $signalRedDark;
    color: $white;
  }
}
