.checkout-confirmation {
  @include checkoutLayout;

  &__text {
    font-size: $baseFontSize;
    line-height: 1.3;
  }

  &__buttons {
    margin-top: 50px;
  }

  &__button {
    display: inline-block;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}
