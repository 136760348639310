.select-car-model {
  &__filter {
    display: inline-block;
    margin: 0;

    @include media('>=tablet') {
      margin: 2rem 0;
    }
  }

  &__search {
    margin-bottom: 2rem;
    border-bottom: 1px solid $grey-200;
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    background: $white;
    transition: border .2s, box-shadow .2s;

    &--no-filter {
      margin: 2rem 0;
    }

    &:hover,
    &--focus {
      border-color: $black;
    }

    @include iconFont(search, before) {
      font-size: 1.5rem;
      margin-right: .5rem;
      margin-bottom: .1rem;
    }

    &--input {
      width: 100%;
      height: 100%;
      padding: .75rem 0;
      border: none;
      color: $black;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $black;
      }
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.25rem;
  }

  &__select {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $grey-800;
    display: block;
    margin-top: .25rem;

    &:hover,
    &:focus,
    &:active {
      color: $black;
      text-decoration: underline;
    }

    &--disable {
      cursor: not-allowed;
      color: $grey-300;
      display: inline-flex;
      align-items: center;
      flex-wrap: nowrap;
      max-width: 100%;
      width: fit-content;

      @include iconFont(info, after) {
        font-size: 1.5rem;
        margin: 0 .5rem;
      }

      &:hover,
      &:focus,
      &:active {
        color: $grey-300;
        text-decoration: none;
      }
    }

    &--text {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
  }
}
