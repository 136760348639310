.social-media {
  display: flex;
  padding: 15px 0;

  @include media('>=tablet') {
    padding: 20px 0;
  }

  &__link {
    display: block;
    border-radius: 50%;
    border: 1px solid $grey-200;
    position: relative;
    width: 32px;
    height: 32px;
    font-size: 12px;
    font-weight: $fontWeightSemiBold;
    text-decoration: none;
    margin: 0 5px;
    color: $white;
    transition: color .2s, border-color .2s;

    &:hover,
    &:focus {
      color: $grey-900;
      border-color: $grey-900;
    }

    &:first-child {
      margin-left: 0;
    }

    &::before {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }

    &--instagram {
      @include iconFont(instagram);
    }

    &--facebook {
      @include iconFont(facebook);
    }

    &--twitter {
      @include iconFont(twitter);
    }

    &--youtube {
      @include iconFont(youtube);
    }

    &--linkedin {
      @include iconFont(linkedin);
    }

    &--google-plus {
      @include iconFont(googlePlus);
    }
  }
}
