.order-summary {
  ul {
    list-style-type: disc;
    color: $grey-500;
  }

  &__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: .5rem 0;

    &:first-child {
      padding-top: 0;
    }

    &--main {
      padding-top: 1rem;
      padding-bottom: 0;
      margin-top: 0;
      border-top: 1px solid $grey-100;
    }

    &--hint {
      padding-bottom: .25rem;
    }
  }

  &__key,
  &__value {
    font-size: $baseFontSize;

    @include media('>laptop') {
      font-size: $fontSizeMedium;
    }

    &--secondary {
      color: $grey-900;

      &_percentage {
        color: $grey-500;
      }
    }

    &--icon {
      color: $grey-900;
      cursor: pointer;

      @include iconFont(info, after) {
        font-size: 1.5rem;
        transform: translateY(20%);
        margin-left: .5rem;
      }
    }
  }

  &__hint {
    font-size: $fontSizeSmall;
    color: $grey-500;
  }

  &__key {
    width: 60%;
  }

  &__value {
    width: 40%;
    text-align: right;

    &--main {
      font-weight: $fontWeightSemiBold;
      font-size: $fontSizeMedium;

      @include media('>laptop') {
        font-size: $fontSizeXXMedium;
      }
    }
  }

  &__additional-info {
    display: block;
    margin-top: 5px;
    font-size: $fontSizeSmall;
    color: $grey-600;

    a {
      color: $grey-600;
      text-decoration: underline;

      &:visited {
        color: $grey-600;
      }
    }
  }

  &__promo-code-form {
    width: 100%;
    padding: 10px;
    background: $white;
  }
}
