.input {
  display: block;
  position: relative;
  padding-top: .5rem;

  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    border-bottom: 1px solid $grey-200;
    background: $white;
    transition: border .2s, box-shadow .2s;

    &:hover,
    &--focus {
      border-color: $black;
    }

    &--error {
      border-color: $signalRedDark;

      &:hover {
        border-color: $black;
      }
    }

    &--success {
      border: 1px solid $signalGreenDark;

      &:hover {
        border: 1px solid $black;
      }
    }

    &--disabled {
      background: $grey-050;
      cursor: not-allowed;

      &:hover {
        border: 1px solid $white;
      }
    }

    &--search {
      .input__button {
        order: 1;
        padding-right: .5rem;
      }

      .input__field {
        order: 2;
      }

      .input__close-icon {
        order: 3;
      }
    }
  }

  &__field {
    width: 100%;
    height: 100%;
    padding: .75rem 0;
    border: none;
    background: none;
    flex: 1;
    line-height: 1rem;
    color: $grey-900;
    font-size: $baseFontSize;

    &:focus {
      outline: none;
    }

    &--disabled {
      opacity: .55;
    }

    &::placeholder {
      color: $grey-500;
    }
  }

  &__button {
    height: 100%;
    flex: 0 0 auto;
    background: none;
    border: none;
    cursor: pointer;
    position: relative;
    text-align: left;
    padding: .5rem 0;
    color: $grey-600;

    &:focus,
    &:hover,
    &:active {
      outline: none;
      color: $black;
    }

    &--icon {
      &_search {
        @include iconFont(search, before) {
          font-size: 1.5rem;
        }
      }

      &_mail {
        @include iconFont(mail, after) {
          font-size: 1.5rem;
        }
      }

      &_eye {
        background-color: transparent;

        @include iconFont(eye, after) {
          font-size: 1.5rem;
          color: $grey-400;
          padding-right: 1rem;
        }
      }

      &_eye-blocked {
        background-color: transparent;

        @include iconFont(eye-blocked, after) {
          font-size: 1.5rem;
          color: $grey-400;
        }
      }

      &_save {
        @include iconFont(save, after) {
          margin-left: 1rem;
          font-size: 1.25rem;
        }
      }
    }
  }

  &__button-icon {
    position: relative;
    z-index: 2;
    font-size: 1.25rem;
    color: $white;
  }

  &__button-text {
    position: relative;
    z-index: 2;
    font-size: $baseFontSize;
    font-weight: $fontWeightRegular;
    color: $white;
    display: block;
    padding-right: 5px;
  }

  &__error {
    margin-top: .25rem;
    position: relative;
    z-index: 5;
    color: $signalRedDark;
    font-size: $fontSizeSmall;
    display: block;
  }

  &__label {
    font-size: .75rem;
    display: block;
    line-height: 1;
    position: absolute;
    top: 0;
    left: 0;
    transition: .15s ease-out, opacity .05s ease-out;
    opacity: 1;

    &--only-mobile {
      @include media('>=tablet') {
        display: none;
      }
    }

    &--as-placeholder {
      pointer-events: none;
      font-size: 1rem;
      top: 1.25rem;
      opacity: 0;
    }

    &--required {
      &::after {
        content: '*';
      }
    }
  }

  &__hint {
    display: block;
    margin-top: .25rem;
    font-size: $fontSizeSmall;
    color: $grey-600;
  }

  &__close-icon {
    display: inline-block;
    border: 1px solid transparent;
    background-color: transparent;
    vertical-align: middle;
    outline: 0;
    cursor: pointer;
    padding: .5rem;

    @include iconFont(close);

    &__active {
      color: $black;
    }

    &__not-active {
      color: $grey-500;
    }

    &__cancel {
      color: $grey-500;
    }
  }
}

input[type="search"]::-webkit-search-cancel-button {
  appearance: none;
}
