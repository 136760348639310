.error-page {
  @include container;

  padding-top: 20px;
  padding-bottom: 20px;

  &__content {
    min-height: 350px;
    position: relative;
  }

  &__headline {
    display: block;
    width: 100%;
    padding-bottom: 2rem;
  }

  &__text {
    margin: 0;
    padding-bottom: 2rem;
  }

  &__button {
    @include media('<=tablet') {
      height: auto;
    }

    display: block;
    margin-top: 20px;
    max-width: 300px;
  }

  &__button-group {
    display: flex;
    gap: 1rem;
  }
}
