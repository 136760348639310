.available-parts {
  &__headline {
    letter-spacing: -.03em;
    font-stretch: 130%;
    padding-bottom: 1rem;
    font-size: 1.5rem;

    @include media('>=tablet') {
      font-size: 2rem;
    }

    span {
      color: $grey-500;
    }

    &--availablePartsSize {
      padding-left: .5rem;

    }

    &--wrapper {
      @include media('>=tablet') {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__name {
    padding-bottom: .75rem;
  }

  &__sorting {
    @include media('<tablet') {
      margin: 1rem 0;
    }
  }

  &__header {
    border-bottom: 1px solid $grey-300;
    display: flex;
    padding: 1rem 0;
  }

  &__cell {
    @include media('>=tablet') {
      padding-left: .5rem;
      padding-right: .5rem;

      &--image,
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    &--content-wrapper {
      @include media('>=tablet') {
        flex-direction: row;
      }
    }

    &--image {
      @include media('>=tablet') {
        width: 25%;
      }
    }

    &--numbers,
    &--price {
      @include media('>=tablet') {
        width: 15%;
      }
    }

    &--damage-model {
      @include media('>=tablet') {
        width: 55%;

        .available-parts__damage-model {
          padding-top: 0;
        }

        .available-parts__damage-model--wrapper {
          padding-bottom: 0;
        }

        .available-parts__link {
          display: block;
          margin-top: 1rem;
        }
      }
    }

    &--basket {
      @include media('>=tablet') {
        text-align: right;
        width: 25%;
        padding-right: 0;
      }
    }

    &--quality {
      @include media('>=tablet') {
        width: 20%;
      }

      a {
        text-decoration: none;
        color: black;

        @include iconFont(info, after) {
          font-size: 1.3rem;
          margin: 0 .5rem;
          vertical-align: bottom;
        }
      }

      span {
        display: block;
        margin-bottom: 1rem;

        &.available-part__delivery-information {
          color: $signalGreenDark;

          @include iconFont(delivery, before, true) {
            vertical-align: bottom;
            margin-right: .25rem;
          }
        }
      }
    }

    &--numbers {
      line-height: 150%;
      white-space: break-spaces;
    }
  }

  &__included-parts {
    padding-top: 1rem;

    &-headline {
      display: block;
    }
  }

  &__origin-parts {
    padding-top: 1rem;

    &-headline {
      padding-top: 1rem;
      display: block;
    }
  }

  &__damage-model {
    padding-top: 1rem;

    &--wrapper {
      padding-bottom: 1rem;
    }

    &--remark {
      color: $grey-500;
    }

    &--delivery-information {
      display: block;
      margin: 1rem 0;
      color: $signalGreenDark;

      @include iconFont(delivery, before, true) {
        vertical-align: bottom;
        margin-right: .25rem;
      }

      @include media('>=tablet') {
        display: none;
      }
    }

    &--headline {
      padding-bottom: 1rem;
    }

    &--headline-basket {
      padding-bottom: .5rem;
    }

    &__bundle {
      @include media('>=tablet') {
        &--active {
          height: auto;
        }

        &--inactive {
          height: 10rem;
          overflow: hidden;
          position: relative;

          &::before {
            content: '';
            width: 100%;
            height: 5rem;
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(to bottom, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 70%) 90%);
          }
        }
      }
    }
  }

  &__additional-images {
    position: relative;

    &--counter {
      position: absolute;
      inset: 0;
      background: rgba($white, .5);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__button-to-cart {
    position: relative;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    @include media('<laptop') {
      width: 100%;
    }

    @include iconFont(cart, before) {
      font-size: 1.2rem;
    }

    &__clicked,
    &__clicked:hover {
      @include iconFont(check-mark, before) {
        font-size: 23px;
        color: inherit;
      }
    }
  }

  &__accordion {
    @include media('>=tablet') {
      .accordion__content-inner {
        padding: 0;
      }
    }

    .accordion__button {
      span {
        display: inline-block;

        & + span {
          padding-left: 5rem;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;

    @include media('<laptop') {
      display: block;
    }
  }

  &__link {
    cursor: pointer;

    &-active {
      font-size: $fontSizeMedium;
      align-items: center;

      @include iconFont(arrowUp, after) {
        vertical-align: bottom;
      }
    }

    &-inactive {
      font-size: $fontSizeMedium;
      align-items: center;

      @include iconFont(arrowDown, after) {
        vertical-align: bottom;
      }
    }
  }

  &__text-wrapper {
    &--header {
      margin-bottom: .5rem;

      &:first-child {
        margin-top: 1.5rem;
      }

      & + span {
        color: $grey-800;
        margin-bottom: 1.5rem;
      }
    }
  }

  &__label {
    font-size: .75rem;
  }

  .reservation-time-icon {
    display: flex;
    gap: .5rem;
    align-items: center;

    @include iconFont(time-small, before) {
      font-size: 1rem;
    }
  }

  &__reservation {
    max-width: 100%;

    & > * {
      width: 100%;
    }
  }

  &__button-open-cart {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  &__button-cancel-reservation {
    align-items: center;
    justify-content: center;
    display: flex;
    gap: .5rem;

    @include iconFont(unlock, before) {
      font-size: 1.2rem;
    }
  }

  &__modal {
    form {
      height: fit-content;
      overflow: hidden auto;
    }

    &__content {
      max-height: calc(100% - 4rem);
      height: 100%;

      @include media('>=tablet') {
        max-height: calc(100% - 8rem);
      }
    }

    &--bottom {
      text-align: center;
    }

    p {
      margin-top: 0;
    }

    &--newBasketName {
      margin: 1rem 0 0 2rem;
      max-width: 20rem;
    }

    &--reservationTime {
      display: block;
      margin-top: 3rem;
      margin-bottom: 1rem;
    }

    &--reservation-time-wrapper {
      max-width: 20rem;
      position: relative;
      padding-top: .5rem;

      .select__custom-dropdown {
        position: relative;
      }
    }

    &--addToCart {
      width: 100%;

      &__error {
        color: $signalRedDark;
      }
    }

    &__added {
      &--headline {
        margin: 2rem 0;

        & > div {
          line-height: 150%;
        }
      }

      &--gallery {
        max-width: 20rem;
        margin-bottom: 2rem;
        color: $grey-800;
      }

      &--qualityClass {
        margin-bottom: 1rem;
        color: $grey-800;
      }

      &--actions {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: .5rem;

        a {
          width: 100%;

          @include media('<tablet') {
            padding: .5rem 1rem;
          }
        }

        @include media('>=tablet') {
          flex-direction: row;
        }
      }
    }
  }
}

.available-part {
  $root: &;

  @include media('>=tablet') {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid $grey-300;
    padding: 1rem 0;

    &:last-child {
      border-bottom: 0;
    }

    &__content {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      width: 100%;
      overflow: hidden;

      &[data-open] {
        overflow: unset;
      }

      &[data-open][data-animation-in-progress] {
        overflow: hidden;
      }

      &[data-open]:not([data-animation-in-progress]) {
        &#{$root}__content--disabled {
          border-bottom: 0;
        }
      }
    }
  }
}
