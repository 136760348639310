.navigation {
  $root: &;

  @include media('>=laptop') {
    position: relative;
    display: flex;
    align-items: center;

    &__link {
      display: block;
      transition: border .2s, color .2s;
      color: $grey-700;
      text-decoration: none;
      white-space: nowrap;
      text-align: center;
      position: relative;

      &--all {
        box-shadow: 0 -2px 10px 0 rgb(0 0 0 / 10%);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 200px;
        text-align: center;
      }
    }

    /* ToDo */
    &__flyout {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: 100%;
      right: 0;
      width: 100%;
      transition: visibility .2s, opacity .2s;
      height: auto;
      background: $white;
      box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      &--all {
        left: 0;
        right: auto;
        width: auto;
      }
    }

    &__element {
      display: inline-block;
      padding: .75rem 0;
      border-bottom: 2px solid transparent;

      &__selected {
        border-bottom: 2px solid $brandPrimaryColor;

        #{$root}__link {
          color: $brandPrimaryColor;
        }
      }

      &__disabled {
        #{$root}__link {
          color: $grey-300;
          cursor: not-allowed;

          &:hover,
          &:focus,
          &:active {
            color: $grey-300;
          }
        }
      }

      .navigation__element__selected.navigation__element__disabled {
        border-bottom: 2px solid $grey-300;
      }

      &:first-child {
        a {
          padding-left: 0;
        }
      }

      &:hover:not(&__disabled) {
        #{$root} {
          &__link {
            color: $brandPrimaryColor;

            &::after {
              transform: rotate(180deg);
            }
          }

          &__flyout {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}
