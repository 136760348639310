.image-magnify {
  position: relative;

  &::before {
    content: '';
    display: none;
  }

  &__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;

    &--visible {
      display: block;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__magnify {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    overflow: hidden;
    border: 1px solid $grey-100;
    cursor: crosshair;
  }

  &__preview-wrapper {
    width: 100%;
    height: 100%;
    background: $white;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    visibility: hidden;
    z-index: $indexImagePreview;

    &--visible {
      visibility: visible;
    }
  }

  &__preview {
    position: relative;
    box-sizing: content-box;
  }
}
