.account-addresses {
  &__section {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $grey-200;
  }

  &__section-name {
    padding-bottom: 20px;
  }

  &__section-edit-name {
    font-size: $baseFontSize;
    color: $black;
    cursor: pointer;

    @include iconFont(edit, after) {
      margin-left: 15px;
      font-size: 18px;
    }

    &--add-new {
      &::after {
        display: none;
      }
    }
  }

  &__address-single {
    max-width: 100%;

    @include media('>=tablet') {
      width: 50%;
      margin: 0 -10px;
      padding: 0 10px;
    }
  }
}
