.product-gallery {
  position: relative;

  a {
    text-decoration: none;
  }

  &__slider {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
    margin: 0 0 1rem;

    @include media('>=laptop') {
      display: flex;
      align-items: center;
      margin: 0;
    }
  }

  &__slides {
    display: flex;
    width: 100%;
    position: relative;
  }

  &__slide {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    height: auto;
    position: relative;
    cursor: pointer;

    &--active {
      display: block;
    }
  }

  &__image {
    display: block;
    height: 100%;
    width: 100%;
  }

  /* &__thumbnails {
    visibility: hidden;
    width: 1px;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: -100;

    @include media('>=laptop') {
      position: relative;
      visibility: visible;
      z-index: auto;
      display: block;
      overflow: hidden;
      height: auto;
      margin: 1rem 0 0;
      width: auto;
    }
  } */

  &__thumb-slides {
    @include media('>=laptop') {
      display: flex;
      width: 100%;
    }
  }

  &__thumb-slide {
    @include media('>=laptop') {
      width: 100%;
      display: block;
      flex-shrink: 0;
      min-width: 70px;
      max-width: 70px;
      border: 1px solid transparent;
      box-sizing: content-box;
      transition: border-color .15s;

      &:hover {
        border-color: $black;
        cursor: pointer;
      }

      &:only-child {
        margin-top: 20px;
      }

      &--active,
      &--active:hover,
      &.swiper-slide-thumb-active {
        border-color: $grey-300;
      }
    }
  }

  &__thumb-wrapper {
    @include media('>=laptop') {
      position: relative;
      height: 4.375rem;
      width: auto;
      cursor: pointer;
      overflow: hidden;
    }
  }

  &__thumbnail {
    max-width: 100%;
    display: block;
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__thumb-button {
    display: block;
    width: 20px;
    height: 25px;
    text-decoration: none;
    position: absolute;
    top: calc(50% - 12px);
    z-index: 1;

    @include media('>=laptop') {
      width: 70px;
      height: 0;
      padding-bottom: 84px;
      position: absolute;
      background: rgb(255 255 255 / 60%);
    }

    @include iconFont(arrowRight) {
      font-size: $fontSizeXXXMedium;
      color: $black;
      transition: color .2s;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover,
    &:focus {
      &::before {
        color: $brandPrimaryColor;
      }
    }

    &--next {
      right: 0;

      @include media('>=laptop') {
        top: 0;
      }
    }

    &--prev {
      left: 0;
      transform: rotate(180deg);

      @include media('>=laptop') {
        left: 0;
        top: 0;
      }
    }

    &--disabled,
    &--hidden {
      visibility: hidden;
    }
  }

  &__button {
    cursor: pointer;
    display: block;
    padding: 1.25rem;
    background-color: rgb(255 255 255 / 40%);
    text-decoration: none;
    position: absolute;
    top: calc(50% - 20px);
    z-index: 1;

    @include iconFont(arrowRight) {
      font-size: $fontSizeXXLarge;
      color: $black;
      transition: color .2s;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover,
    &:focus {
      &::before {
        color: $brandPrimaryColor;
      }
    }

    &--next {
      right: .5rem;
    }

    &--prev {
      left: .5rem;
      transform: rotate(180deg);
    }

    &--disabled,
    &--hidden {
      visibility: hidden;
    }
  }

  &__pagination {
    width: 100%;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    @include media('>=laptop') {
      visibility: hidden;
      height: 0;
      width: 0;
    }
  }

  &__pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    background: $grey-100;
    transition: background .2s;
    margin: 0 2.5px;

    &--active {
      background: $brandPrimaryColor;
    }
  }

  &__placeholder {
    margin-bottom: 30px;

    @include media('>=tablet') {
      margin-bottom: 50px;
    }

    @include media('>=laptop') {
      margin-bottom: 100px;
    }

    &-image {
      display: block;
      padding: 30px 0;
      width: 100%;
      height: 250px;
      position: relative;

      @include media('>=tablet') {
        height: 550px;
      }

      > img {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &-slider {
      position: relative;
      overflow: hidden;
      height: auto;
      width: 100%;

      @include media('>=laptop') {
        width: 490px;
      }
    }

    &-slides {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }

    &-slide {
      width: 100%;
      height: 100%;
      display: block;
      flex-shrink: 0;
    }

    &-thumbnail {
      width: 100%;
      height: 100%;
      position: relative;
      cursor: pointer;

      @include media('>=laptop') {
        width: 70px;
        height: 80px;
      }

      @include componentPreloader {
        display: block;
        width: 100%;
      }
    }
  }

  &__video {
    font-size: $baseFontSize;
    color: $grey-500;
    display: block;
    cursor: pointer;
    font-weight: $fontWeightSemiBold;
    padding: 20px 0 20px 25px;
    position: relative;

    &-content {
      padding: 20px 0 0;
    }

    @include iconFont(video) {
      font-size: $fontSizeXXMedium;
      position: absolute;
      left: 0;
      top: 15px;
    }
  }
}

.gallery {
  .swiper-slide-thumb-active {
    .product-gallery__thumb-wrapper {
      border: 1px solid $grey-300;
    }
  }

  .product-gallery {
    &__swiper-thumbs {
      margin-top: 1.5rem;

      img {
        cursor: pointer;
      }
    }
  }

  &__button-prev,
  &__button-next {
    display: none;
    transform: translate3d(0, 0, 0);

    @include media('>=tablet') {
      position: absolute;
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: transparent;
      color: $grey-200;
      margin-top: 0;
      top: 0;
    }

    &::after {
      content: '';
    }
  }

  .swiper-pagination {
    position: relative;
    padding-top: 10px;

    &-bullet-active {
      opacity: 1;
      background: $black;
    }
  }
}
