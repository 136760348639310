.textarea {
  display: block;

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
    border: 1px solid $grey-200;
    transition: border .2s, box-shadow .2s;
    background: $white;

    &:hover,
    &--focus {
      border-color: $brandPrimaryColor;
    }

    &--focus {
      box-shadow: 0 0 10px 0 rgb(0 0 0 / 25%);
    }

    &--error {
      border: 1px solid $signalRedDark;

      &:hover {
        border: 1px solid darken($signalRedDark, 15);
      }
    }

    &--success {
      border: 1px solid $signalGreenDark;

      &:hover {
        border: 1px solid darken($signalGreenDark, 15);
      }
    }

    &--disabled {
      background: $grey-100;
      cursor: not-allowed;

      &:hover {
        border: 1px solid $grey-200;
      }
    }
  }

  &__field {
    resize: none;
    width: 100%;
    height: 100%;
    padding: 10px 15px;
    min-height: 100px;
    font-size: $baseFontSize;
    border: none;
    background: none;
    flex: 1;

    &:focus {
      outline: none;
    }

    &--disabled {
      opacity: .55;
    }

    &::placeholder {
      @include fontSize($baseFontSize);

      color: $grey-500;
    }
  }

  &__error {
    margin-top: 8px;
    position: relative;
    z-index: 5;
  }

  &__label {
    font-size: $fontSizeSmall;
    display: block;
    margin: 5px 0;
    max-height: 50px;
    overflow: hidden;
    transform: translate(0, 0);
    transition: transform .15s ease-out, opacity .05s ease-out;
    opacity: 1;

    &--only-mobile {
      @include media('>=tablet') {
        display: none;
      }
    }

    &--as-placeholder {
      pointer-events: none;
      transform: translate(15px, 35px);
      padding-bottom: 0;
      opacity: 0;
    }

    &--required {
      &::after {
        content: '*';
      }
    }
  }
}
