.captcha {
  &__input {
    width: 160px;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
    border: 1px solid $grey-200;
    border-radius: 5px;
    transition: border .2s, box-shadow .2s;

    &:hover {
      box-shadow: 0 0 10px 0 rgb(0 0 0 / 25%);
      border-color: $brandPrimaryColor;
    }

    &--error {
      border: 1px solid $signalRedDark;

      &:hover {
        border: 1px solid darken($signalRedDark, 15);
      }
    }

    &--success {
      border: 1px solid $signalGreenDark;

      &:hover {
        border: 1px solid darken($signalGreenDark, 15);
      }
    }
  }

  &__image {
    width: 120px;
    height: 50px;
  }

  &__error {
    @include fontSize($baseFontSize);

    color: $signalRedDark;
    display: block;
    margin-top: 8px;
  }

  &__captcha-reload {
    display: inline-block;
    cursor: pointer;
    transition: transform .2s;
    position: absolute;
    right: 20px;
    top: 5px;

    @include iconFont(reload) {
      font-size: $fontSizeXXMedium;
      padding: 5px;
      color: $brandPrimaryColor;
    }

    &--rotate {
      transform: rotateZ(360deg);
    }
  }
}
