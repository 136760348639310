.image-teasers-set {
  $root: &;

  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -5px;

  &__teaser {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 20px;
  }

  &--items {
    &_1 {
      #{$root}__teaser {
        width: 100%;
      }
    }

    &_2 {
      #{$root}__teaser {
        width: 100%;

        @include media('>=tablet') {
          width: 50%;
        }
      }
    }

    &_3 {
      #{$root}__teaser {
        width: 100%;

        @include media('>=tablet') {
          width: calc(100% / 3);
        }
      }
    }

    &_4 {
      #{$root}__teaser {
        width: 100%;

        @include media('>=tablet') {
          width: 50%;
        }

        @include media('>laptop') {
          width: 25%;
        }
      }
    }

    &_5 {
      #{$root}__teaser {
        width: 100%;

        @include media('>=tablet') {
          width: calc(100% / 3);
        }
      }
    }

    &_6 {
      #{$root}__teaser {
        width: 100%;

        @include media('>=tablet') {
          width: 50%;
        }

        @include media('>laptop') {
          width: calc(100% / 3);
        }
      }
    }

    &_7 {
      #{$root}__teaser {
        width: 100%;

        @include media('>=tablet') {
          width: 50%;
        }

        @include media('>laptop') {
          width: calc(100% / 3);
        }
      }
    }

    &_8 {
      #{$root}__teaser {
        width: 100%;

        @include media('>=tablet') {
          width: 50%;
        }

        @include media('>laptop') {
          width: 25%;
        }
      }
    }
  }
}
