.address-wrapper {
  &__headline {
    margin-bottom: .5rem;
  }

  span {
    display: block;
    font-style: normal;

    &:empty {
      padding: 0;
      margin: 0;
      height: 0;
    }
  }
}
