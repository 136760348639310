.brands-tiles-teaser {
  &__list {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
  }

  &__tile {
    width: 50%;
    border-left: 1px solid $grey-200;
    border-top: 1px solid $grey-200;

    &:first-child,
    &:nth-child(2n + 1) {
      border-left: none;
    }

    &:nth-child(-n+2) {
      border-top: none;
    }

    @include media('>=tablet') {
      width: calc(100% / 5);

      &:nth-child(2n + 1) {
        border-left: 1px solid $grey-200;
      }

      &:nth-child(5n + 1) {
        border-left: none;
      }

      &:nth-child(-n+5) {
        border-top: none;
      }
    }

    @include media('>laptop') {
      width: calc(100% / 7);

      &:nth-child(5n + 1) {
        border-left: 1px solid $grey-200;
      }

      &:nth-child(7n + 1) {
        border-left: none;
      }

      &:nth-child(-n+7) {
        border-top: none;
      }
    }
  }
}
