.account-welcome-view {
  &__button-wrapper {
    padding: 20px 0 0;

    @include media('>=tablet') {
      display: flex;
      justify-content: space-between;
    }
  }

  &__button {
    display: block;
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    @include media('>=tablet') {
      width: auto;
      max-width: 300px;
      margin-bottom: 0;
      margin-right: 20px;
      flex: 1;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__last-order {
    padding-top: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $grey-200;
  }
}
