.cart-page {
  @include container;

  @include media('>=laptop') {
    display: flex;
    gap: 8rem;
    padding-top: 2rem;
  }

  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    @include media('>=tablet') {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }

    &--count {
      color: $grey-500;
    }
  }

  &__empty,
  &__main {
    width: 100%;

    @include media('>=laptop') {
      width: calc(75% - 8rem);
    }
  }

  &__content {
    &__product {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid $grey-200;

      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
      }
    }
  }

  &__footer {
    width: 100%;

    @include media('>=laptop') {
      width: 25%;
    }
  }

  &__order-info {
    order: 4;

    @include media('>=tablet') {
      order: 1;
      width: 50%;
    }

    @include media('>laptop') {
      width: 60%;
    }
  }

  &__order-summary {
    width: 100%;
  }

  &__submit-button {
    display: block;
  }

  &__cart-select {
    width: 30%;

    @include media("<tablet") {
      width: 100%;
    }
  }

  &__limit-exceeded {
    color: #d9534f;
  }

  .b2bBasketLimit {
    position: relative;
    width: 300px;
  }
}

[is="cart-page"] {
  min-height: 740px;

  @include spinner;

  * {
    display: none;
  }
}
