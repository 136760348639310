.category-image-teaser {
  display: flex;
  flex-direction: column;

  &__headline {
    padding-bottom: 1rem;
  }

  &__entry {
    align-items: center;
    display: flex;
    padding-top: 1rem;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &--icon {
      width: 48px;
      height: 48px;
    }

    &--label {
      padding: 0 1rem;
      color: $grey-900;
    }

    &--wrapper {
      display: inline-block;

      @include media('>tablet') {
        width: calc(100% / 3);
      }

      @include media('>phone', '<=tablet') {
        width: 50%;
      }

      @include media('<=phone') {
        width: 100%;
      }
    }
  }
}
