.account {
  &__back-button {
    display: inline-flex;
    gap: .25rem;
    align-items: center;
    text-decoration: none;
    color: $black;
    margin: 1rem 0;

    @include media(">tablet") {
      margin-top: 0;
    }

    @include iconFont(arrowLeft, before) {
      font-size: 1.5rem;
      margin-left: -.5rem;
    }
  }
}
