.footer-seo {
  width: 100%;
  max-width: 1210px;
  padding: 40px 15px 0;
  margin: auto;
  display: block;

  h1,
  h2,
  h3 {
    font-size: $fontSizeXXMedium;
    color: $grey-900;
    margin: 0 0 20px;
    text-align: left;
    padding: 0;
  }

  p {
    font-size: $baseFontSize;
    color: $grey-900;
    margin: 0 0 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
