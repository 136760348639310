.burger-menu {
  width: 338px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  transform: translateX(-338px);
  transition: transform .3s;
  overflow: hidden auto;
  display: block;
  z-index: $indexBurgerMenu;
  background: $whiteColor;
  padding: 2rem;

  @include media('>laptop') {
    display: none;
  }

  &--open {
    transform: translateX(0);
  }

  &__logo {
    width: 4.5rem;
  }

  &__close-button {
    display: block;
    text-align: center;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 2.2rem;
    right: 2rem;

    @include iconFont(close) {
      color: $brandPrimaryColor;
      font-size: $fontSizeXXMedium;
    }
  }

  &__nav-wrapper {
    margin-top: 2rem;
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100% - 7rem);
    margin-bottom: 6rem;
  }

  &__meta-content {
    display: block;

    @include media('>laptop') {
      display: none;
    }
  }

  &__language-switcher {
    padding: 1rem;
    position: relative;
    z-index: 1;

    .language-switcher {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__user-shortcuts {
    display: flex;
    flex-direction: column;

    .user-shortcut__link {
      flex-direction: inherit;
    }

    .user-shortcut__label {
      display: block;
      padding-left: .625rem;
    }

    .user-shortcut__notification-badge {
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__user-shortcut {
    margin-bottom: 1.5rem;
  }

  &__button-wrapper {
    position: fixed;
    background-color: $white;
    bottom: 2rem;
    text-align: center;
    width: calc(100% - 4rem);
    display: flex;
    align-items: center;
    gap: 1rem;

    .button {
      width: 100%;
    }
  }

  &__header {
    margin-bottom: 2.5rem;
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  &__select-car-model-mobil-wrapper {
    display: flex;
    justify-content: space-between;

    .tag {
      border: none !important;

      @include media('>=tablet') {
        border: 1px solid $black !important;
      }
    }
  }
}
