.filters {
  $root: &;

  &__wrapper {
    display: none;

    @include media('>=tablet') {
      position: static;
      display: block;
    }

    &--mobile-active {
      z-index: $indexPlpFilters;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 100%;
      position: fixed;
      inset: 0;
      background: $white;
      padding: 2rem;
    }
  }

  &__list {
    overflow-y: auto;
    max-height: 100%;

    @include media('>=tablet') {
      overflow-y: initial;
    }
  }

  &__mobile-toggles {
    display: flex;
    justify-content: space-between;
    gap: .5rem;

    @include media('>=tablet') {
      display: none;
    }
  }

  &__open-filters {
    width: 50%;
    border: 1px solid $grey-800;
    text-align: left;
    height: 40px;
    line-height: 40px;
    padding: 0 2.5rem;
    position: relative;
    background: $white;
    color: $grey-900;

    @include iconFont(options) {
      position: absolute;
      left: 1rem;
      top: 50%;
      font-size: 1.25rem;
      transform: translateY(-50%);
      color: $black;
    }
  }

  &__products-sort {
    width: 50%;
    border: 1px solid $grey-800;
  }

  &__header {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    background: $white;

    @include media('>=tablet') {
      padding: 0;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 3;
    background: $white;
    padding: 15px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);

    @include media('>=tablet') {
      position: static;
      box-shadow: none;
      padding: 0;
    }
  }

  &__button {
    width: 100%;
    border: 1px solid $black;
  }

  &__mobile-column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__mobile-buttons {
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;
  }

  &__mobile-close-button {
    cursor: pointer;
    display: flex;

    @include iconFont(close) {
      font-size: 1.5rem;
      color: $black;
    }

    @include media('>=tablet') {
      display: none;
    }
  }

  &__clear-all-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid $black;

    @include iconFont(close) {
      color: $black;
      font-size: 1.5rem;
      margin-right: 5px;
    }
  }

  &__apply-button {
    width: 100%;
    height: 40px;
    background: $brandPrimaryColor;
    font-size: $fontSizeXMedium;
    color: $white;
    text-align: center;
    position: relative;
    border: none;
    cursor: pointer;
    display: block;
    margin-bottom: 10px;

    @include media('>=tablet') {
      display: none;
    }

    @include iconFont(options) {
      color: $white;
    }
  }

  &__filter {
    border-bottom: 1px solid $grey-050;

    &:first-child {
      border-top: 1px solid $grey-050;
    }

    &:last-child {
      border-bottom: none;
    }

    &--disabled {
      display: none;
    }
  }

  &__filter-toggle {
    background: none;
    border: none;
    display: block;
    width: 100%;
    padding: 1rem 0;
    text-align: left;
    position: relative;
    cursor: pointer;
    color: inherit;

    &:active,
    &:focus {
      outline: none;
    }

    @include iconFont(arrowDown) {
      right: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.5rem;
      color: $black;

      @include media('>=tablet') {
        right: 0;
      }
    }

    &--active {
      &::before {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &__filter-dropdown {
    padding: 0 15px;
    position: relative;
    overflow: hidden;

    @include media('>=tablet') {
      padding: 0;
    }

    &--price {
      .filter-price {
        padding-bottom: 20px;
      }
    }

    &[data-open] {
      overflow: unset;
    }

    &[data-animation-in-progress] {
      overflow: hidden;
    }
  }

  &__options {
    display: none;
    padding: 0 15px;
    position: relative;

    @include media('>=tablet') {
      padding: 0;
    }

    &::before {
      content: '';
      width: 100%;
      height: 20px;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(to bottom, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 100%) 90%);
      z-index: 3;
    }

    &--visible {
      display: block;
    }
  }

  &__line-placeholder {
    @include componentPreloader {
      width: 100%;
      height: 22px;
      display: block;
      margin-bottom: 10px;
    }

    &--swatch {
      width: 42px;
      height: 42px;
      margin: 0 6px 6px 0;
      display: inline-block;
    }
  }
}
