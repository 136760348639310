.footer {
  width: 100%;

  &__row-wrapper {
    display: block;
    background: $black;
    color: $whiteColor;

    @include media('<tablet') {
      padding-bottom: 1rem;
    }
  }

  &__row {
    @include container;

    display: block;
    padding-left: 0;
    padding-right: 0;

    @include media('>=tablet') {
      padding: 2rem 2rem 1rem;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 2rem;

    @include media('>=tablet') {
      flex-flow: row nowrap;
      justify-content: flex-end;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    text-align: center;

    @include media('<tablet') {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 1rem;
    }

    @include media('>=tablet') {
      text-align: right;
      padding-bottom: 0;
      padding-right: 0;
    }

    .footer__link {
      display: block;

      a {
        color: $whiteColor;
        text-decoration: none;
      }
    }
  }

  &__logotypes {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include media('<tablet') {
      margin-top: 2.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5rem;
    width: 100%;

    @include media('>=laptop') {
      width: 30%;
    }
  }

  &__copyright {
    color: $grey-400;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 1.5rem;

    @include media('<tablet') {
      align-items: center;
      text-align: center;
      margin-top: 2.5rem;
    }
  }
}
