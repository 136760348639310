.category-tile {
  background: $grey-100;
  border-radius: 10px;
  padding: 15px;

  @include media('>=tablet') {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  @include media('>laptop') {
    padding: 20px;
  }

  &__product-wrapper {
    @include media('>=tablet') {
      width: 200px;
      padding-right: 20px;
    }

    @include media('>laptop') {
      width: 240px;
      padding-right: 30px;
    }
  }

  &__product,
  .product-box--placeholder {
    background: $white;
    border-radius: 5px;
    margin-bottom: 15px;

    @include media('>=tablet') {
      margin-bottom: 0;
    }
  }

  &__content {
    @include media('>=tablet') {
      width: calc(100% - 200px);
    }

    @include media('>laptop') {
      width: calc(100% - 240px);
    }
  }

  &__main-category {
    font-size: $fontSizeMedium;
    font-weight: $fontWeightSemiBold;
    color: $grey-900;
    text-decoration: none;
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: block;
    border-bottom: 1px solid $grey-200;

    &:hover {
      color: $grey-900;
      text-decoration: none;
    }

    @include iconFont(arrowRight, after) {
      font-size: 10px;
    }

    @include media('>laptop') {
      padding-bottom: 15px;
      margin-bottom: 15px;

      &:hover {
        color: $brandPrimaryColor;
      }
    }
  }

  &__subcategories {
    display: none;

    @include media('>=tablet') {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
    }
  }

  &__subcategory {
    @include media('>=tablet') {
      color: $grey-900;
      text-decoration: none;
      padding: 5px 0;
      display: block;
      width: 100%;

      &:hover {
        color: $grey-900;
        text-decoration: none;
      }
    }

    @include media('>laptop') {
      width: calc(50% - 5px);

      &:hover {
        color: $brandPrimaryColor;
      }
    }

    @include iconFont(arrowNext, after) {
      font-size: 8px;
    }
  }
}
