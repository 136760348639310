.slide-fade-leave-active,
.slide-left-enter-active {
  transition: all 1.5s ease-in-out;
}

.slide-left-enter {
  transform: translateX(10px);
}

.slide-left-leave,
.slide-left-leave-to {
  transform: translateX(10px);
}

.slide-leave-active,
.slide-enter-active {
  transition: .5s;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(-100%);
}

.slide-enter-to,
.slider-leave-from {
  transform: translateY(0);
}