.navigation-flyout {
  @include media('>laptop') {
    &__wrapper {
      padding: 25px;
    }

    &__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
    }

    &__categories-list {
      @include clearList;

      margin-top: 15px;

      &--main {
        columns: 3;
        padding: 25px 0;
        margin-top: 0;
        width: 100%;
      }

      &--size {
        &_75 {
          width: 75%;
        }
      }
    }

    &__image-wrapper {
      width: 25%;
      display: block;
    }

    &__image {
      display: block;
      width: 100%;
      height: auto;
    }

    &__categories-element {
      margin-bottom: 7px;

      &--main {
        break-inside: avoid;
        padding-bottom: 25px;
        margin-bottom: 0;
      }
    }

    &__categories-link {
      font-size: $baseFontSize;
      color: $grey-900;
      text-decoration: none;

      &:hover,
      &:visited {
        color: $grey-900;
        text-decoration: none;
      }

      &:hover {
        color: $brandPrimaryColor;
      }

      &--main {
        font-weight: $fontWeightSemiBold;
      }
    }

    &__footer {
      width: 100%;
      padding-top: 25px;
      border-top: 1px solid $grey-100;
    }

    &__footer-link {
      display: inline-flex;
      align-items: center;
      font-size: $baseFontSize;
      color: $grey-900;
      text-decoration: none;

      @include iconFont(arrowRight, after);

      &:hover,
      &:visited {
        color: $grey-900;
        text-decoration: none;
      }

      &:hover {
        color: $brandPrimaryColor;
      }

      i {
        font-size: $baseFontSize;
        color: $brandPrimaryColor;
        margin-left: 5px;
      }
    }
  }
}
