.date-input {
  position: relative;

  &--active {
    z-index: $indexFlyout;
  }

  &__input {
    position: relative;
    z-index: 3;
  }

  &__label {
    padding-bottom: 1px;
  }

  &__date-picker {
    position: absolute;
    z-index: 2;
    background: $grey-050;
    width: 100%;
    margin-top: 10px;
  }

  &__error {
    margin-top: 8px;
    position: relative;
    z-index: 1;
  }
}
