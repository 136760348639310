.lazy-load-image {
  position: relative;

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    display: block;

    &--not-loaded {
      opacity: 0;
    }
  }

  &__loader,
  &__error {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
  }

  &__loader {
    animation: 2s linear infinite lazyLoadImageLoader;

    @include iconFont(loading) {
      font-size: 30px;
      color: $grey-600;
    }
  }

  &__error {
    @include iconFont(camera) {
      font-size: 30px;
      color: $grey-600;
    }
  }

  &--fallback {
    background-color: $grey-025;
  }
}

@keyframes lazyLoadImageLoader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
