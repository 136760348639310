.brand-tile {
  width: 100%;
  height: 100px;
  position: relative;
  display: block;

  &__image {
    display: block;
    filter: grayscale(100%);
    width: auto;
    height: auto;
    max-width: calc(100% - 20px);
    max-height: calc(100% - 20px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
