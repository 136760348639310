.login-form {
  &__link {
    margin: 15px 0;
  }

  &__error {
    margin: 1em 0 0;
    color: $signalRedDark;
  }

  &__form .input {
    &__wrapper {
      &--login {
        border: 1px solid $white;
        background: $black;
      }
    }

    &__field {
      padding: 1rem;
      color: $white;

      &::placeholder {
        color: $grey-200;
      }
    }

    &__error {
      color: $signalRedLight;
    }
  }
}
