.active-filters {
  $root: &;

  margin-left: -3px;
  margin-right: -3px;
  position: relative;

  @include media('>=tablet') {
    padding-right: 150px;
  }

  &__filter,
  &__remove-all {
    margin: 3px;
  }

  &__remove-all {
    cursor: pointer;

    @include media('>=tablet') {
      margin: 0;
      right: 0;
    }

    @include media('<tablet') {
      display: none;
    }
  }

  &__selecetd {
    @include media('<tablet') {
      display: none;
    }
  }
}
