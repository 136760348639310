.promo-item {
  padding: 10px 10px 10px 110px;
  transition: box-shadow .2s;
  position: relative;
  min-height: 110px;
  cursor: pointer;

  @include media('>laptop') {
    &:hover {
      box-shadow: 0 0 5px 0 rgb(0 0 0 / 30%);
    }

    &--disabled {
      &:hover {
        box-shadow: none;
      }
    }
  }

  &__disabled {
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
    }
  }

  &__selected {
    cursor: pointer;
  }

  &__info {
    &--disabled {
      opacity: .7;
    }
  }

  &__image {
    position: absolute;
    max-width: 80px;
    max-height: 80px;
    width: auto;
    height: auto;
    display: block;
    top: 10px;
    left: 10px;

    &--disabled {
      opacity: .7;
    }
  }

  &__name {
    display: block;
    font-weight: $fontWeightMedium;
    text-decoration: none;
    color: $grey-900;

    &:visited,
    &:hover {
      text-decoration: none;
      color: $grey-900;
    }
  }

  &__article-number,
  &__variations {
    margin-top: 10px;
    display: block;
    color: $grey-600;
    font-size: $fontSizeSmall;

    span {
      &::after {
        content: '|';
      }

      &:last-child {
        &::after {
          content: none;
        }
      }
    }
  }

  &__form {
    position: absolute;
    bottom: 12px;
    right: 10px;

    @include media("<tablet") {
      right: 0;
    }
  }

  &__button {
    display: block;
    width: 20px;
    height: 20px;

    &--select {
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 2px;
        height: 12px;
        background: $grey-900;
      }

      &::after {
        transform: translate(-50%, -50%) rotate(90deg);
      }

      &:hover {
        &::after,
        &::before {
          text-decoration: none;

          @include media('>laptop') {
            background: $brandPrimaryColor;
          }
        }
      }
    }

    &--selected {
      background-color: $signalGreenLight;
      border-radius: 12px;

      @include iconFont(checked) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $white;
        font-weight: $fontWeightBold;
        font-size: $fontSizeXSmall;
      }

      &:hover::before {
        text-decoration: none;
      }
    }
  }
}
