.wishlist-page {
  @include container;

  &__info {
    @include fontSize($fontSizeMedium);

    margin: 15px 0;
    font-weight: $fontWeightSemiBold;
  }

  &__link {
    color: $brandPrimaryColor;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__hint {
    font-size: $fontSizeMedium;
    font-weight: $fontWeightBold;
    margin: 0 0 15px;
  }

  &__name {
    @include fontSize($fontSizeLarge);

    display: block;
    width: 100%;
    margin: 30px 0;
    padding: 0;
    font-weight: $fontWeightSemiBold;
  }

  &__top {
    display: flex;
    align-items: center;
  }

  &__button-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    @include media('>=tablet') {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  &__button {
    width: 100%;

    @include media('>=tablet') {
      width: auto;
    }

    &--left {
      @include media('>=tablet') {
        margin-right: auto;
      }
    }
  }

  &__all-to-basket {
    margin-bottom: 20px;

    @include media('>=tablet') {
      margin-bottom: 0;
    }
  }

  &__product {
    width: calc(100% / 2);
    border-right: 1px solid $grey-100;
    border-bottom: 1px solid $grey-100;

    &:nth-child(2n) {
      border-right: none;
    }

    @include media('>=tablet') {
      width: calc(100% / 3);
      border: none;
    }

    @include media('>=laptop') {
      width: calc(100% / 4);
      border: none;
    }
  }

  &__products-container {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
  }

  .wishlist-position {
    margin-bottom: 10px;

    @include media('>=tablet') {
      flex-wrap: nowrap;
    }

    .product-order-position {
      &__cell {
        padding: 0;
        width: 100%;

        @include media('>=tablet') {
          padding: 0 10px;
        }

        &--price {
          order: 3;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      &__edit-button {
        display: inline-block;

        &:visited {
          color: $grey-600;
        }

        &:hover {
          @include media('>laptop') {
            color: $brandPrimaryColor;
          }
        }
      }

      &__to-cart-button {
        padding: 0;
        background: none;
        border: none;
        color: $grey-600;
        cursor: pointer;
        display: inline-block;

        &:visited {
          color: $grey-600;
        }

        &:hover {
          @include media('>laptop') {
            color: $brandPrimaryColor;
          }
        }

        @include iconFont(cart) {
          font-size: 17px;
        }
      }
    }

    &__wishlist-buttons {
      display: flex;
      justify-content: space-between;

      @include media('<tablet') {
        width: 100%;
        padding-top: 10px;
      }
    }

    &__wishlist-button {
      &::after {
        font-size: $fontSizeXMedium;
        padding: 8px 0;
      }

      &--edit,
      &--remove {
        border: 2px solid $black;

        @include media('<=laptop', '>phone') {
          margin-right: 5px;
        }
      }

      &--to-cart {
        margin-right: 0;
      }
    }
  }
}
