* {
  box-sizing: border-box;
}

body,
html {
  display: block;
  font-family: $fontFamily;
  font-weight: $fontWeightRegular;
  font-size: 16px;
  color: $black;
  line-height: 1.5;
  width: 100vw;
  overflow: hidden scroll;
  margin: 0;
  background-color: $white;

  /*
  The overscroll-behavior CSS property sets what a browser/devices does when reaching the boundary of a scrolling area.
  By default, mobile browsers tend to provide a "bounce" effect or even a page refresh when the top or bottom of a page (or other scroll area) is reached.
  */
  overscroll-behavior-y: none;

  & > div {
    min-height: 100vh;
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .page-main {
    flex: 1;
  }
}

hr {
  border: none;
  border-top: 1px solid $grey-100;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

button {
  font-family: $fontFamily;
}

a {
  cursor: pointer;
}

small {
  font-size: .75rem;
}

input,
select,
textarea {
  font-family: $fontFamily;
}

.underlined {
  text-decoration: underline;
}

.show-more {
  @include iconFont(arrowDown, after);
}

.show-less {
  @include iconFont(arrowUp, after);
}

/* change order with this class in display: flex */
@for $i from 1 through 6 {
  .ordered_#{$i} {
    order: $i;
  }

  @include media('<tablet') {
    .ordered-mobile_#{$i} {
      order: $i;
    }
  }
}

@mixin scrollBar($size: 12px, $trackColor: $white, $thumbColor: $white) {
  /* custom scrollbar (not working in firefox) */
  /* height = x-, width = y- */
  &::-webkit-scrollbar {
    height: $size;
    width: $size;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: $trackColor;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumbColor;
    outline: none;
    border-radius: 5px;
  }

  /* end custom scrollbar */
}

@mixin hideScrollBar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }
}
