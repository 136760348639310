.checkout-step-login {
  @include checkoutLayout;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 0 auto;
  }

  &__form {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 30px;

    @include media('>=tablet') {
      padding: 10px 40px;
      border-right: 1px solid $grey-200;
    }

    &:first-child {
      @include media('>=tablet') {
        padding-left: 0;
      }
    }

    &:last-child {
      @include media('>=tablet') {
        padding-right: 0;
        border-right: none;
      }
    }

    &--size {
      &_50 {
        @include media('>=tablet') {
          width: 50%;
        }
      }
    }
  }
}
