@font-face {
  font-family: AudiType;
  src:
    url("../fonts/AudiTypeVF_4.04.woff2") format("woff2-variations"),
    url("../fonts/AudiTypeVF_4.04.ttf") format("ttf-variations");
  font-weight: 125 950;
  font-stretch: 75% 125%;
  font-style: oblique 0deg 20deg;
}

@font-face {
  font-family: AudiType;
  src:
    url("../fonts/AudiTypeVF_4.04.woff2") format("woff2-variations"),
    url("../fonts/AudiTypeVF_4.04.ttf") format("ttf-variations");
  font-stretch: 130%;
  font-style: normal;
}

@font-face {
  font-family: AudiType;
  src:
    url("../fonts/AudiType-Italic_4.03.woff2") format("woff2-variations"),
    url("../fonts/AudiType-Italic_4.03.ttf") format("ttf-variations");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: AudiType;
  src:
    url("../fonts/AudiType-ExtendedItalic_4.03.woff2") format("woff2-variations"),
    url("../fonts/AudiType-ExtendedItalic_4.03.ttf") format("ttf-variations");
  font-style: italic;
  font-weight: normal;
  font-stretch: 130%;
}

@font-face {
  font-family: AudiType;
  src:
    url("../fonts/AudiType-BoldItalic_4.03.woff2") format("woff2-variations"),
    url("../fonts/AudiType-BoldItalic_4.03.ttf") format("ttf-variations");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: AudiType;
  src:
    url("../fonts/AudiType-ExtendedBoldItalic_4.03.woff2") format("woff2-variations"),
    url("../fonts/AudiType-ExtendedBoldItalic_4.03.ttf") format("ttf-variations");
  font-weight: bold;
  font-style: italic;
  font-stretch: 130%;
}

@font-face {
  font-family: audi-iconfont;
  src:
    url("../fonts/audi-iconfont.woff") format("woff"),
    url("../fonts/audi-iconfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/audi-iconfont.eot"),
    url("../fonts/audi-iconfont.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
