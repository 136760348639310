.account-single-order-cart {
  &__headline {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  &__content-wrapper {
    overflow: hidden;
  }

  &__opener {
    @include iconFont(arrowDown, after) {
      font-size: 3rem;
    }

    border: none;
    margin: 0;
    padding: .5rem 0;
    background: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    &--open {
      @include iconFont(arrowUp, after) {
        font-size: 3rem;
      }
    }
  }

  &__product {
    border-bottom: 0;

    .product-order-position__footer {
      justify-content: end;
    }
  }
}