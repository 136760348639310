.tooltip {
  position: absolute;
  bottom: calc(100% + 5px);
  left: 50%;
  width: auto;
  white-space: nowrap;
  transform: translateX(-50%);
  padding: 10px;
  color: $grey-900;
  border-radius: 5px;
  border: 1px solid $grey-100;
  background-color: $grey-100;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: $grey-100 transparent transparent transparent;
  }

  &--bottom {
    bottom: auto;
    top: calc(100% + 5px);

    &::after {
      top: auto;
      bottom: 100%;
      border-color: transparent transparent $grey-100;
    }
  }
}
