.products-list-page {
  @include sideColumnLayout;

  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;

  &__header {
    width: 100%;
    display: flex;
    padding-bottom: 1rem;
    justify-content: space-between;
    align-items: center;

    @include media('>=tablet') {
      border-bottom: 1px solid $grey-100;
      margin-bottom: 3rem;
    }
  }

  &__header-left {
    display: flex;
  }

  &__loader {
    background: rgb(255 255 255 / 50%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;

    &--hidden {
      display: none;
    }
  }

  &__column {
    display: block;
    margin-bottom: 1rem;
  }

  &__name {
    text-align: left;
    text-transform: none;
  }

  &__teaser-top {
    margin: 0 0 30px;
    width: 100%;

    @include media('>=tablet') {
      margin: 0 0 50px;
    }
  }

  &__teaser-bottom {
    width: 100%;
    margin: 30px 0;
  }

  &__sidebar-menu {
    display: none;

    @include media('>=tablet') {
      display: block;
      margin-bottom: 25px;
      padding-bottom: 25px;
      border-bottom: 1px solid $grey-200;
    }
  }

  &__top-bar {
    display: none;

    @include media('>=tablet') {
      display: flex;
      justify-content: end;
      align-items: flex-start;
    }
  }

  &__bottom-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;

    @include media('>=tablet') {
      padding-top: 1rem;
    }
  }

  &__products-sort {
    display: none;

    @include media('>=tablet') {
      display: block;
      width: 280px;
    }
  }

  &__products-count {
    &--text {
      margin-left: .5rem;

      @include media('<tablet') {
        margin-left: 1rem;
      }
    }
  }

  &__active-filters {
    padding-bottom: 1rem;
  }

  &__insertion {
    width: 100%;

    &--top {
      margin-bottom: 30px;
    }

    &--bottom {
      margin-top: 30px;
    }
  }

  &__last-search-terms {
    display: block;
    width: 100%;
    margin-bottom: 30px;
  }

  &__content-search-results {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $grey-200;
  }

  &__no-results-message {
    padding-top: 1.25rem;
    border-top: 1px solid $grey-800;

    @include media(">=tablet") {
      padding-top: 2.5rem;
    }
  }
}

[is="products-list-page"] {
  min-height: 740px;

  @include spinner;

  * {
    display: none;
  }
}
