.product-features {
  display: block;

  &__element {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;

    @include media('<phone-lg') {
      display: block;
    }
  }

  &__item {
    &-name {
      flex: 1 1 20%;
      padding-left: 5px;
      font-weight: $fontWeightSemiBold;

      @include media("<=tablet") {
        flex: 1 1 50%;
      }
    }

    &-value {
      flex: 1 1 80%;
      padding-left: 5px;

      @include media("<=tablet") {
        flex: 1 1 50%;
      }
    }
  }

  &__item-even {
    background-color: $grey-100;
  }

  &__item-image {
    max-width: 40px;
    margin-right: 5px;
  }
}
