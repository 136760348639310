.divider {
  position: relative;
  margin: 20px 0;
  height: 1px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 1px;
    background-color: $grey-100;
  }
}
