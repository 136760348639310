.radio-inputs-list {
  $root: &;

  display: block;
  margin-top: 1rem;

  &__option {
    align-items: center;
    display: flex;
    gap: .75rem;
    position: relative;
    cursor: pointer;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__input {
    display: none;

    &:checked {
      + #{$root}__radio-circle {
        box-shadow: inset 0 0 0 1px $grey-900;

        &::before {
          content: '';
          display: block;
          width: .625rem;
          height: .625rem;
          background: $grey-900;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        + #{$root}__option-content {
          border-color: $black;

          #{$root}__option-name {
            color: $black;
          }
        }
      }
    }
  }

  &__radio-circle {
    position: relative;
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    min-height: 1.25rem;
    box-shadow: inset 0 0 0 1px $grey-500;
    display: block;
    border-radius: 50%;
  }

  &__option-content {
    width: 100%;
  }

  &__option-name {
    color: $grey-700;
    display: block;
  }

  &__option-description {
    color: $grey-900;
    display: block;
    margin-top: .5rem;
  }
}
