.accordion {
  $root: &;

  &:not(.accordion--mobile-only) {
    border-bottom: 1px solid $grey-100;
  }

  &--mobile-only {
    @include media('<tablet') {
      border-bottom: 1px solid $grey-100;
    }
  }

  &__button {
    cursor: pointer;
    border: none;
    background: none;
    display: block;
    width: 100%;
    padding: 2rem 0;
    margin: 0;
    text-align: left;
    color: $brandPrimaryColor;
    position: relative;
    z-index: 2;

    &[aria-disabled="true"] {
      @include media('>=tablet') {
        outline: none;
      }
    }

    @include iconFont(arrowDown, before) {
      font-size: 1.5rem;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      position: absolute;
    }

    &--open {
      &::before {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &--mobile-only {
      @include media('>=tablet') {
        padding-top: 0;
        border-bottom: none;

        &::after,
        &::before {
          display: none;
        }

        &#{$root}__button--open {
          &::after,
          &::before {
            display: none;
          }
        }
      }
    }
  }

  &__content {
    overflow: hidden;

    &[data-open] {
      overflow: unset;
    }

    &[data-open][data-animation-in-progress] {
      overflow: hidden;
    }

    &[data-open]:not([data-animation-in-progress]) {
      @include media('>=tablet') {
        &#{$root}__content--disabled {
          border-bottom: 0;
        }
      }
    }

    &--mobile-only {
      @include media('>=tablet') {
        overflow: unset;
      }
    }
  }

  &__content-inner {
    padding: 10px 0 20px;
  }
}
