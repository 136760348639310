$fontFamily: 'AudiType', 'Corbel', 'Helvetica', sans-serif;

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: .5em 0;
  padding: 0;
}

/* // Font weights */
$fontWeightThin: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemiBold: 600;
$fontWeightBold: 700;

/*
// @deprecated (-> novosales)
// Font sizes
*/
$fontSizeXSmall: .6rem;
$fontSizeSmall: .75rem;
$baseFontSize: 1rem;
$fontSizeMedium: 1rem;
$fontSizeXMedium: 1.125rem;
$fontSizeXXMedium: 1.25rem;
$fontSizeXXXMedium: 1.5rem;
$fontSizeLarge: 1.75rem;
$fontSizeXLarge: 2rem;
$fontSizeXXLarge: 2.5rem;
$fontSizeXXXLarge: 3rem;
$fontSizeHuge: 4rem;

/* AUDI Font-Styles, just add the class to element to get the styles */
$fontStyleMap: (
  "h1": (
    size: $fontSizeXXLarge,
    weight: 400
  ),
  "h2": (
    size: $fontSizeXLarge,
    weight: 400
  ),
  "h3": (
    size: $fontSizeXXXMedium,
    weight: 400
  ),
  "headline-big": (
    size: 36px,
    weight: 400
  ),
  "default-regular": (
    size: $baseFontSize,
    weight: 400
  ),
  "default-semi-bold": (
    size: $baseFontSize,
    weight: 600
  ),
  "default-bold": (
    size: $baseFontSize,
    weight: 700
  ),
  "small-regular": (
    size: $fontSizeSmall,
    weight: 400
  ),
  "small-semi-bold": (
    size: $fontSizeSmall,
    weight: 600
  ),
  "small-bold": (
    size: $fontSizeSmall,
    weight: 700
  ),
  "big-regular": (
    size: $fontSizeXXMedium,
    weight: 400
  ),
  "big-semi-bold": (
    size: $fontSizeXXMedium,
    weight: 600
  ),
  "big-bold": (
    size: $fontSizeXXMedium,
    weight: 700
  ),
  "bigger-regular": (
    size: $fontSizeXLarge,
    weight: 400
  ),
  "bigger-semi-bold": (
    size: $fontSizeXLarge,
    weight: 600
  ),
  "bigger-bold": (
    size: $fontSizeXLarge,
    weight: 700
  ),
  "biggest-regular": (
    size: $fontSizeXXLarge,
    weigth: 400
  ),
  "icon-regular": (
    size: 24px,
    weight: 700
  )
);

@each $fontStyle, $i in $fontStyleMap {
  .#{$fontStyle} {
    font-size: map-get($i, "size");
    font-weight: map-get($i, "weight");
  }
}
