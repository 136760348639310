.products-sort {
  position: relative;

  @include iconFont(arrowDown) {
    position: absolute;
    right: .5rem;
    top: 50%;
    font-size: 1rem;
    transform: translateY(-50%);
    color: $grey-900;
  }

  &__select {
    text-align: left;
    color: $grey-900;
    height: 100%;
    width: 80%;
    padding: .5rem .75rem;
    position: relative;
    z-index: 1;
    appearance: none;
    background: none;
    cursor: pointer;
    transition: border .2s, box-shadow .2s;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media('>=tablet') {
      border: 1px solid $grey-200;
      width: 100%;

      &:hover,
      &:focus {
        border: 1px solid $black;
        outline: none;
      }

      &:focus {
        box-shadow: 0 0 .5rem 0 rgba(0 0 0 / 20%);
      }
    }
  }
}

/* // PLACEHOLDER STYLES */

/* stylelint-disable-next-line */
products-sort,
[is="products-sort"] {
  @include media('>=tablet') {
    height: 42px;
    border: 1px solid $grey-200;
    border-radius: 0;
  }
}
