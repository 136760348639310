.checkout-process-breadcrumbs {
  overflow: hidden;

  @include media('>=tablet') {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    border: 1px solid $grey-200;
  }

  &__breadcrumb {
    display: none;
    color: $grey-600;
    text-decoration: none;
    text-align: center;

    @include media('>=tablet') {
      display: block;
      padding: 10px 5px 10px 35px;
      font-size: $baseFontSize;
      position: relative;
      background: $grey-100;

      &::before {
        content: '';
        width: 35px;
        height: 35px;
        position: absolute;
        background: $grey-100;
        border: 1px solid $grey-200;
        left: 100%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        z-index: 1;
      }

      &::after {
        content: '';
        position: absolute;
        z-index: 2;
        height: 100%;
        width: 30px;
        right: 0;
        top: 0;
        background: $grey-100;
      }

      &:first-child {
        padding-left: 25px !important;
      }

      &:last-child {
        padding-right: 25px !important;
      }
    }

    &:visited {
      color: $grey-900;
      text-decoration: none;
    }

    &--ready {
      @include media('>=tablet') {
        background: $white;
        color: $grey-900;

        &::before {
          background: $white;
        }

        &::after {
          background: $white;
        }
      }
    }

    &--previous {
      display: block;
      font-size: $fontSizeSmall;
      padding: 5px;
      border-top: 1px solid $grey-200;
      border-left: 1px solid $grey-200;
      border-right: 1px solid $grey-200;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      @include iconFont(arrowLeft) {
        font-size: 10px;
      }

      @include media('>=tablet') {
        padding: 10px 5px 10px 35px;
        border: none;
        font-size: $baseFontSize;

        &::before {
          content: '';
        }
      }
    }

    &--current {
      display: block;
      font-size: $baseFontSize;
      background: $brandPrimaryColor;
      padding: 10px;
      color: $white;

      @include media('>=tablet') {
        padding: 10px 5px 10px 35px;

        &::before {
          background: $brandPrimaryColor;
        }

        &::after {
          background: $brandPrimaryColor;
        }
      }
    }
  }

  &__breadcrumb-name {
    @include media('>tablet') {
      display: block;
      position: relative;
      z-index: 3;
    }
  }
}
