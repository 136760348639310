.counter {
  height: 40px;
  position: relative;
  border: 1px solid $grey-200;
  background: $white;
  overflow: hidden;
  padding: 0 80px 0 0;
  min-width: 120px;

  &__input {
    width: 100%;
    height: 100%;
    text-align: center;
    background: none;
    border: none;
    outline: none;
    font-size: $baseFontSize;
    font-weight: $fontWeightSemiBold;

    &:focus,
    &:active {
      outline: none;
    }

    &[disabled] {
      color: $grey-900;
      -webkit-text-fill-color: $grey-900;
    }
  }

  &__button {
    position: absolute;
    height: 100%;
    width: 40px;
    transition: background .2s;
    background: $white;
    border: none;
    cursor: pointer;
    border-left: 1px solid $grey-200;

    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 2px;
      background: $grey-900;
      border-radius: 2px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 9px;
    }

    &--increase {
      top: 0;
      right: 0;

      &::after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    &--decrease {
      top: 0;
      right: 40px;

      &::after,
      &::before {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    &--disabled {
      cursor: not-allowed;

      &:hover {
        background: $white;
      }

      &::after,
      &::before {
        background: $grey-500;
      }
    }
  }
}
