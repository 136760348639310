.accordions-list {
  &__html-content {
    margin-bottom: 20px;

    @include media('>=tablet') {
      margin-bottom: 30px;
    }
  }

  &__accordion {
    border-bottom: 1px solid $grey-200;

    &:last-child {
      border-bottom: none;
    }
  }
}
