.cookie-disclaimer {
  $fontColor: $white;

  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9000;
  padding: 1rem;
  color: $fontColor;
  background-color: $brandPrimaryColor;

  @include media('<desktop') {
    flex-direction: column;
  }

  &__text a {
    color: $fontColor;
  }

  &__types {
    display: flex;
    margin: 0 2rem;
    gap: 2rem;

    @include media('<desktop') {
      width: 100%;
      justify-content: space-between;
      margin: 1rem 0;
      gap: 1rem;
    }

    @include media('<phone-lg') {
      flex-wrap: wrap;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    min-width: 250px;

    @include media('<desktop') {
      width: 100%;
    }

    @include media('<desktop','>=phone-lg') {
      flex-direction: row;
      justify-content: space-between;
    }

    .button {
      @include media('<desktop') {
        flex: 1 1 50%;
      }

      &:not(:last-child) {
        margin-bottom: .25rem;

        @include media('<desktop','>=phone-lg') {
          margin-bottom: 0;
          margin-right: 1rem;
        }
      }
    }
  }
}
