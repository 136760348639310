.image-asset-list {
  &__list {
    @include clearList;

    display: flex;
    flex-wrap: wrap;

    @include media('>=tablet') {
      flex-wrap: nowrap;
    }

    &--align {
      &_end {
        @include media('>=tablet') {
          align-self: flex-end;
        }
      }
    }
  }

  &__image-wrapper {
    position: relative;
    display: block;
    margin: 0 10px;

    @include media('>=tablet') {
      margin: 0 20px;
    }

    &:first-child {
      margin-left: 0;
    }

    &--type {
      &_payment {
        width: 65px;
        height: 35px;
        margin-left: 0;

        @include media('>=tablet') {
          width: 85px;
        }
      }

      &_logo {
        width: 90px;
        height: 30px;

        &:last-child {
          margin-right: 0;
        }

        @include media('>=tablet') {
          width: 120px;
        }
      }
    }
  }

  &__image {
    display: block;
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
  }
}
