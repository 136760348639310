.switch {
  position: relative;
  display: block;
  width: 56px;
  height: 32px;

  .slider {
    position: absolute;
    cursor: pointer;
    inset: 0;
    background-color: $grey-200;
    transition: .4s;

    &::before {
      position: absolute;
      content: "";
      height: 24px;
      width: 24px;
      left: 4px;
      bottom: 4px;
      background-color: $white;
      transition: .4s;
    }

    &__round {
      border-radius: 32px;

      &::before {
        border-radius: 50%;
      }
    }

    &__disabled {
      cursor: not-allowed;
    }
  }

  input {
    display: none;

    &:focus + .slider {
      box-shadow: 0 0 1px $brandPrimaryColor;
    }

    &:checked + .slider {
      background-color: $brandPrimaryColor;
    }

    &:checked + .slider::before {
      transform: translateX(24px);
    }
  }
}
