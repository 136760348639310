.date-picker {
  $root: &;

  &__days-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 0 8% 10px 0;
  }

  &__day {
    width: calc((100% / 7) - 6px);
    margin-right: 7px;
    text-align: right;

    &:nth-child(7n) {
      margin-right: 0;
    }
  }

  &__day-label {
    display: block;
    width: 100%;
    height: 100%;
    padding: 5px;
    cursor: pointer;
    text-align: right;

    &:hover {
      background: $grey-100;
    }
  }

  &__day-input {
    display: none;

    &:checked {
      + #{$root}__day-label {
        background: $brandPrimaryColor;
        color: $white;
      }
    }

    &:disabled {
      + #{$root}__day-label {
        color: $grey-500;
        cursor: not-allowed;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 5px;
    background-color: $brandPrimaryColor;

    &__days {
      color: $whiteColor;
      padding: 0 8% 5px 0;
    }
  }

  &__select-wrapper {
    position: relative;
    padding: 0 20px;
    height: 32px;
  }

  &__select {
    border: none;
    height: 100%;
    width: 100%;
    font-size: $baseFontSize;
    appearance: menulist;
    padding: 0 10px;
    text-align: center;
    cursor: pointer;

    &:hover,
    &:active {
      outline: none;
    }
  }

  &__change-select-button {
    width: 15px;
    height: 100%;
    cursor: pointer;
    position: absolute;
    top: 0;

    &::before {
      color: $white;
      font-size: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;
    }

    &--prev {
      left: 0;

      @include iconFont(arrowLeft);
    }

    &--next {
      right: 0;

      @include iconFont(arrowRight);
    }

    &--disabled {
      box-shadow: none;
      cursor: not-allowed;

      &::before {
        color: $grey-500;
      }
    }
  }
}
