.service-teaser {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-decoration: none;

  &:hover,
  &:visited {
    text-decoration: none;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 26px;
    color: $brandPrimaryColor;

    @include media('>=tablet') {
      font-size: 30px;
    }

    @include media('>laptop') {
      font-size: $fontSizeXXXLarge;
    }
  }

  &--icon {
    &_nm-icon-callback-service {
      @include iconFont(headset);
    }

    &_nm-icon-delivery-24h {
      @include iconFont(shipping);
    }

    &_nm-icon-gift-voucher {
      @include iconFont(gift);
    }

    &_nm-icon-shop-cart {
      @include iconFont(cart);
    }

    &_nm-icon-return {
      @include iconFont(return);
    }

    &_nm-icon-express-delivery {
      @include iconFont(time);
    }

    &_nm-icon-check {
      @include iconFont(checked);
    }
  }

  &--theme {
    &_dark {
      background-color: $grey-900;
    }

    &_bright {
      background-color: $signalGreenLight;
    }

    &_highlight {
      background-color: $grey-100;
    }

    &_discreet {
      background-color: lighten($signalRedLight, 30%);
    }

    &_orange {
      background-color: $brandPrimaryColor;
    }

    &_dark-grey {
      background-color: $grey-700;
    }

    &_light-grey {
      background-color: $grey-100;
    }
  }

  &__content {
    padding-left: 40px;
    min-height: 50px;
    width: 100%;
    max-width: 180px;
    color: $grey-900;
    font-size: $baseFontSize;
    font-weight: $fontWeightSemiBold;
    display: flex;
    justify-content: center;
    flex-flow: column;
    overflow-wrap: break-word;

    @include media('>laptop') {
      padding-left: 70px;
      max-width: 210px;
    }
  }
}
