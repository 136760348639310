.breadcrumbs {
  @include container;

  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  z-index: 2;
  position: relative;
  padding-top: 2rem;

  &--seo {
    display: none;
  }

  &__item {
    display: block;
    position: relative;
    padding-right: 1rem;
    margin-right: 1rem;

    @include iconFont(arrowRight, after) {
      position: absolute;
      top: 50%;
      right: -1em;
      transform: translate(-50%, -50%);
    }

    & > * {
      max-width: 15rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:last-child {
      &::after {
        content: none;
      }
    }
  }

  &__link {
    display: block;
    color: $grey-500;
    text-decoration: none;
    transition: color .15s;

    &:hover,
    &:focus,
    &:active {
      color: $black;
    }

    &--current {
      color: $black;
    }
  }

  &__mobile {
    @include iconFont(arrowLeft) {
      font-size: 1.5rem;
      color: $grey-500;
    }
  }
}
