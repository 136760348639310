$buttonStyleMap: (
  "outlined-default": (
    color: $black,
    background: $white,
    border: $black
  ),
  "filled-default": (
    color: $white,
    background: $black,
    border: $black
  ),
  "filled-white": (
    color: $black,
    background: $white,
    border: $white
  ),
  "outlined-white": (
    color: $white,
    background: $black,
    border: $white
  ),
  "outlined-danger": (
    color: $signalRedDark,
    background: $white,
    border: $signalRedDark,
  ),
  "filled-danger": (
    color: $white,
    background: $signalRedDark,
    border: $signalRedDark
  ),
  "outlined-success": (
    color: $signalGreenDark,
    background: $white,
    border: $signalGreenDark
  ),
  "filled-success": (
    color: $white,
    background: $signalGreenDark,
    border: $signalGreenDark
  )
);

.button {
  $root: &;

  transition: background .15s linear, border-color .15s linear, color .15s linear;
  cursor: pointer;
  text-align: center;
  padding: 1rem 2rem;
  min-width: 9rem;
  max-width: 100%;
  width: auto;
  position: relative;
  color: $black;
  font-weight: $fontWeightRegular;
  font-size: $baseFontSize;
  border: 1px solid $black;
  background: transparent;
  text-decoration: none;
  display: inline-block;
  font-stretch: 105%;

  &::after {
    transition: color .15s linear;
  }

  &:hover {
    color: $white;
    background: $grey-900;
    text-decoration: none;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &.small {
    height: auto;
    padding: .5rem 1rem;
  }

  /* add the class to button to get the style, example: button button--outlined-default  */
  @each $buttonStyle, $i in $buttonStyleMap {
    &--#{$buttonStyle} {
      color: map-get($i, "color");
      background: map-get($i, "background");
      border-color: map-get($i, "border");

      &:focus,
      &:active,
      &:hover {
        color: lighten(map-get($i, "color"), 25%);
        background-color: lighten(map-get($i, "background"), 25%);
        border-color: lighten(map-get($i, "border"), 25%);
      }

      &:disabled {
        cursor: not-allowed;
        color: lighten(map-get($i, "color"), 50%);
        background-color: lighten(map-get($i, "background"), 50%);
        border-color: lighten(map-get($i, "border"), 50%);
      }
    }
  }

  /* ToDo icons for buttons */
  &--icon {
    padding: 1rem 2.5rem;

    &::after {
      font-size: 1.5rem;
      font-weight: $fontWeightSemiBold;
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
    }

    &--front {
      &::after {
        right: auto;
        left: 1rem;
      }
    }

    &_reload {
      @include iconFont(reload, after);
    }

    &_close {
      @include iconFont(close, after);
    }

    &_next {
      @include iconFont(arrowRight, after);
    }

    &_back {
      padding-left: 2.5rem;
      padding-right: 2rem;

      @include iconFont(arrowLeft, before) {
        left: 1rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &_cart {
      @include iconFont(cart, after) {
        font-size: $fontSizeXMedium;
        font-weight: $fontWeightMedium;
      }
    }

    &_edit {
      @include iconFont(edit, after);
    }

    &_remove {
      @include iconFont(trash, after);
    }

    &_checked {
      @include iconFont(checked, after);
    }
  }
}
