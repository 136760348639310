.promo-items-list {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 20px;
  border: 1px solid $grey-200;
  position: relative;

  &__headline {
    position: absolute;
    top: -8px;
    font-weight: $fontWeightMedium;
    font-size: $fontSizeMedium;
    background: #fff;
    padding: 0 10px;
    left: 10px;
  }

  &__item {
    width: 50%;

    @include media("<tablet") {
      width: 100%;
    }

    @include media(">=desktop") {
      width: 25%;
    }
  }
}
