.account-personal-data-form {
  text-decoration: none;

  &__sub-headline {
    padding-top: .5rem;
    width: 100%;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 2rem;

    &_input {
      display: flex;
      flex: 0 0 50%;
      flex-direction: column;
      margin-top: 1rem;

      &--headline {
        font-size: $fontSizeSmall;
        width: 100%;

        &--disabled {
          font-size: $fontSizeSmall;
          color: $grey-300;
        }
      }

      &--field {
        font-size: $fontSizeMedium;
        width: 95%;
        border: 0;
        border-bottom: 1px $black solid;
        padding: .5rem 0;

        &:focus-visible {
          outline: none;
        }

        &:disabled {
          background-color: transparent;
          cursor: not-allowed;
          border-bottom: 1px $grey-300 solid;
          color: $grey-300;
        }
      }
    }
  }
}
