.account-orders-list-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .75rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid $grey-100;
  }

  &__order-number {
    display: flex;
    flex: 0 0 33%;
  }

  &__invoice-wrapper {
    display: flex;
    flex-direction: column;
    align-items: end;
    flex: 0 0 33%;
  }

  &__order-button {
    display: flex;
    align-items: center;
    gap: .5rem;

    &-download {
      @include iconFont(download);

      padding-left: 1.5rem;
    }
  }
}
