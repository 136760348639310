.account-orders-list {
  &__headline {
    @include media('>tablet') {
      margin-top: 0;
    }
  }

  &__order {
    display: flex;
    padding: 1rem 0 .5rem;
    align-items: center;
    gap: .5rem;
    justify-content: space-between;

    &-wrapper {
      border-bottom: 1px solid $grey-100;
      padding: 1.5rem 0;
      display: flex;
      align-items: center;

      &:last-child {
        border-bottom: 0;
      }
    }

    &-header {
      display: flex;
      gap: .5rem 2rem;
      flex-wrap: wrap;
      align-items: center;
    }

    &-content {
      width: 100%;
    }


    &-images {
      display: flex;
      gap: 1rem;
      width: 20rem;
      flex-shrink: 0;

      @include media('<=desktop') {
        width: 14rem;
      }

      span {
        max-width: 6rem;

        @include media('<=desktop') {
          max-width: 4rem;
        }
      }

      &--counter {
        display: flex;
        align-items: center;
        text-align: center;
      }
    }

    &-image {
      display: block;
      height: auto;
      max-width: 100%;
      width: auto;
    }

    &-status {
      width: 20%;
    }

    &-price {
      @include media('<=tablet') {
        display: none;
      }
    }

    &-details {
      margin-left: 2rem;

      @include media('<=tablet') {
        display: none;
      }
    }

    &-button {
      display: flex;
      align-items: center;
      gap: .5rem;
      align-self: flex-start;

      &-arrow {
        @include iconFont(arrowRight, after);

        padding-right: 1.5rem;

        @include media('<=tablet') {
          display: none;
        }
      }

      &-mobile-arrow {
        flex-shrink: 0;
        cursor: pointer;

        @include iconFont(arrowRight) {
          font-size: $fontSizeLarge;
          font-weight: $fontWeightSemiBold;
        }

        margin-left: 10px;

        @include media('>tablet') {
          display: none;
        }
      }

      &-wrapper {
        display: flex;
        gap: .5rem;
        justify-content: flex-end;
        flex: 0 0 33%;

        @include media('>tablet') {
          flex: 0 0 40%;
        }

        &__invoice {
          display: flex;
          flex-direction: column;
          align-items: end;

        }
      }
    }

    .button {
        @include media('<laptop') {
          min-width: 0;
        }
    }

    &-origin-info {
      display: block;

    }
  }

  &__extra-space {
    margin-bottom: 1rem;
  }
}
