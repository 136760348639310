.checkbox {
  $root: &;

  cursor: pointer;

  &:hover {
    #{$root}__label {
      color: $black;
    }

    &.inverse #{$root}__label {
      color: $white;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    gap: .75rem;
  }

  &__input {
    display: none;

    &:checked {
      + #{$root}__box {
        border-color: $black;
        background-color: $black;

        .inverse & {
          border-color: $white;
        }

        @include iconFont(checked) {
          font-size: 1.125rem;
          color: $white;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .inverse & {
            color: $white;
          }
        }

        + #{$root}__label {
          color: $grey-900;

          .inverse & {
            color: $white;
          }
        }
      }
    }

    &[disabled] {
      + #{$root}__box {
        border-color: $grey-500;

        .inverse & {
          border-color: $grey-500;
        }

        @include iconFont(checked) {
          color: $grey-500;

          .inverse & {
            color: $grey-400;
          }
        }
      }

      ~ #{$root}__label {
        color: $grey-500 !important;

        .inverse & {
          color: $grey-400 !important;
        }
      }
    }
  }

  &__box {
    display: block;
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    min-height: 1.25rem;
    border: 1px solid $grey-500;
    position: relative;
  }

  &__label {
    font-size: .875rem;
    color: $grey-700;

    .inverse & {
      color: $grey-200;
    }

    &--swatch {
      display: none;
    }
  }
}
