.headline {
  display: block;
  width: 100%;
  font-weight: $fontWeightRegular;
  margin: 0;

  &--type {
    &_teaser {
      font-size: 1.5rem;
      font-stretch: 125%;
      letter-spacing: -.015rem;

      @include media('<laptop') {
        font-size: 1.25rem;
      }
    }
  }

  &--type_h1,
  h1 {
    /* stylelint-disable-next-line */
    @extend .h1;

    font-stretch: 125%;
    letter-spacing: -.015rem;

    @include media('<laptop') {
      font-size: 2rem;
    }
  }

  &--type_h2,
  h2 {
    /* stylelint-disable-next-line */
    @extend .h2;

    font-stretch: 125%;
    letter-spacing: -.015rem;

    @include media('<laptop') {
      font-size: 1.5rem;
    }
  }

  &--type_h3,
  h3 {
    /* stylelint-disable-next-line */
    @extend .h3;

    font-stretch: 125%;
    letter-spacing: -.015rem;

    @include media('<laptop') {
      font-size: 1.25rem;
    }
  }

  h4,
  &.h4,
  h5,
  &.h5,
  h6,
  &.h6 {
    font-size: 1rem;
    font-stretch: 125%;
    letter-spacing: -.015rem;
  }
}
