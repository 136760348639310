.service-links {
  padding: 0 0 0 40px;

  &__list {
    @include clearList;
  }

  &__item {
    padding: 5px;
  }

  &__link {
    font-size: $baseFontSize;
    font-weight: $fontWeightThin;
    color: $grey-500;
    text-decoration: none;

    &:visited {
      text-decoration: none;
    }

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
}
