.product-description > * {
  font-size: $baseFontSize;
  margin: 0;
  color: $grey-500;
  font-weight: $fontWeightRegular;
  line-height: 1.5;
}

.product-description {
  font-size: $baseFontSize;
  margin: 0;
  color: $grey-500;
  font-weight: $fontWeightRegular;
  line-height: 1.5;

  &__quality-keys {
    padding: 20px 0;
  }

  &__quality-keys-headline {
    @include fontSize($fontSizeXMedium);

    width: 100%;
    padding: 20px 0;
    text-align: left;
    font-weight: $fontWeightSemiBold;
    margin: 0;
  }

  &__keys-list {
    list-style: disc;
    padding-left: 20px;
    margin: 0;
  }

  &__keys-item {
    font-size: $baseFontSize;
    color: $grey-500;
    padding: 5px 0;
  }
}
