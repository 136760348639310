/* // This file contains overwitten styles from node_modules/photoswipe/dist/photoswipe.css */
.pswp {
  $root: &;

  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  touch-action: none;
  z-index: $indexModal;
  outline: none;

  img {
    max-width: none;
  }

  &--animate_opacity {
    opacity: .001;
    will-change: opacity;
    transition: opacity .3s cubic-bezier(.4, 0, .22, 1);
  }

  &--open {
    display: block;
  }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $grey-900;
    opacity: 0;
    transform: translateZ(0);
    will-change: opacity;
    transition: opacity .3s cubic-bezier(.4, 0, .22, 1);

    #{$root}--animated-in & {
      transition: none;
    }
  }

  &__scroll-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__container,
  &__zoom-wrap {
    touch-action: none;
    position: absolute;
    inset: 0;
    backface-visibility: hidden;
  }

  &__container,
  &__img {
    user-select: none;
  }

  &__zoom-wrap {
    position: absolute;
    width: 100%;
    transform-origin: left top;
    transition: transform .3s cubic-bezier(.4, 0, .22, 1);

    #{$root}--animated-in & {
      transition: none;
    }
  }

  &__item {
    position: absolute;
    inset: 0;
    overflow: hidden;
  }

  &__img {
    position: absolute;
    width: auto;
    height: auto;
    top: 0;
    left: 0;

    &--placeholder {
      backface-visibility: hidden;
    }

    &--placeholder--blank {
      background: $grey-900;
    }

    #{$root}--ie & {
      width: 100% !important;
      height: auto !important;
      left: 0;
      top: 0;
    }

    #{$root}--zoom-allowed & {
      cursor: zoom-in;
    }

    #{$root}--zoomed-in & {
      cursor: grab;
    }

    #{$root}--dragging & {
      cursor: grabbing;
    }
  }

  &__error-msg {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
    font-size: $baseFontSize;
    line-height: 16px;
    margin-top: -8px;
    color: $signalRedDark;

    a {
      color: $grey-900;
      text-decoration: underline;
    }
  }

  &__ui {
    visibility: visible;
    opacity: 1;
    z-index: 1550;
  }

  &__button {
    width: 30px;
    height: 30px;
    position: absolute;
    background: none;
    cursor: pointer;
    overflow: visible;
    display: block;
    border: 0;
    padding: 0;
    margin: 0;
    opacity: .9;
    transition: opacity .2s;
    box-shadow: none;

    @include media('>tablet') {
      width: 50px;
      height: 50px;
    }

    &:focus,
    &:hover {
      opacity: 1;
    }

    &:active {
      outline: none;
      opacity: .9;
    }

    &--arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-decoration: none;
      color: $grey-300;

      &::before {
        font-size: $fontSizeLarge;
      }

      &:visited {
        color: $grey-300;
      }

      @include media('>laptop') {
        &::before {
          font-size: $fontSizeXXLarge;
        }

        &:hover {
          &::before {
            color: $brandPrimaryColor;
          }
        }
      }

      /*
      // #{$root}--touch & {
      //   visibility: hidden;
      // }
      */

      #{$root}--has_mouse & {
        visibility: visible;
        will-change: opacity;
        transition: opacity .3s cubic-bezier(.4, 0, .22, 1);
      }

      #{$root}--idle & {
        opacity: 0;
      }

      #{$root}__ui--hidden & {
        opacity: .001;
      }

      #{$root}__ui--one-slide & {
        display: none;
      }
    }

    &--arrow--left {
      left: 0;

      @include iconFont(arrowLeft);
    }

    &--arrow--right {
      right: 0;

      @include iconFont(arrowRight);
    }

    &--close {
      position: relative;
      margin-left: auto;

      @include iconFont(close) {
        font-size: $fontSizeMedium;
        color: $white;
      }

      #{$root}--over-close & {
        opacity: 1;
      }
    }

    &--fs {
      position: relative;
      display: block;

      @include iconFont(fullScreen) {
        font-size: $fontSizeMedium;
        color: $white;
      }

      #{$root}--fs & {
        @include iconFont(exitFullScreen);
      }
    }

    &--zoom {
      position: relative;
      display: block;

      @include iconFont(zoomIn, before, true) {
        font-size: $fontSizeMedium;
        color: $white;
      }
    }
  }

  &__counter {
    user-select: none;
    font-size: $baseFontSize;
    line-height: 50px;
    color: $white;
    opacity: .9;
    margin-right: auto;

    #{$root}__ui--one-slide & {
      display: none;
    }
  }

  &__preloader {
    position: relative;
  }

  &__preloader__donut {
    /* stylelint-disable-next-line */
    @extend .loader;
  }

  &__top-bar {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    top: 0;
    height: 50px;
    padding: 0 20px;
    width: 100%;
    background-color: rgb(0 0 0 / 50%);
    will-change: opacity;
    transition: opacity .3s cubic-bezier(.4, 0, .22, 1);

    #{$root}--fit & {
      background-color: rgb(0 0 0 / 30%);
    }

    #{$root}__ui--hidden & {
      opacity: .001;
    }

    #{$root}--idle & {
      opacity: 0;
    }

    #{$root}--minimal--dark & {
      background: none;
    }
  }

  &__element--disabled {
    display: none !important;
  }
}
