.half-size-teaser {
  $root: &;

  width: 100%;
  height: 380px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-decoration: none;

  @include media('>laptop') {
    &:hover {
      #{$root}__image {
        transform: scale(1.2);
      }
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    transition: transform 1s;
    z-index: 1;
    background-size: cover;
    background-position: center;
  }

  &__text {
    padding: 20px;
    width: 100%;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__headline,
  &__subheadline {
    display: block;
    text-align: center;
    text-transform: uppercase;
    color: $white;

    &:hover,
    &:visited {
      color: $white;
    }

    &--color {
      &_dark {
        color: $black;

        &:hover,
        &:visited {
          color: $black;
        }
      }
    }
  }

  &__headline {
    font-size: $fontSizeHuge;
    font-weight: $fontWeightBold;
  }

  &__subheadline {
    font-size: $fontSizeXXXLarge;
    font-weight: $fontWeightThin;
  }
}
