.component-placeholder {
  animation: 1s linear forwards infinite placeHolderAnimation;
  background: #f6f7f8;
  background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 200% 100%;
  position: relative;
}

@keyframes placeHolderAnimation {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}

@mixin componentPreloader() {
  /* stylelint-disable-next-line */
  @extend .component-placeholder;

  @content;
}
