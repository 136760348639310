/* // Spinner */
@use "sass:math";
@mixin spinner($size: 30) {
  @include iconFont(loading) {
    font-size: #{$size}px;
    display: block;
    position: absolute;
    animation: spin 1.5s infinite linear;
    color: $brandPrimaryColor;
    top: 50%;
    left: 50%;
    margin: -#{math.div($size, 2)}px 0 0 -#{math.div($size, 2)}px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
