@mixin sideColumnLayout() {
  @include container;

  display: block;

  @include media('>=tablet') {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
  }

  &__column {
    display: none;

    @include media('>=tablet') {
      display: block;
      width: calc(25% - 15px);
      padding-right: 20px;
    }
  }

  &__content {
    width: 100%;

    @include media('>=tablet') {
      width: calc(75% + 15px);
      padding-left: 20px;
    }
  }
}
