.product-label {
  padding: 12px 10px;
  position: relative;
  color: $white;
  text-transform: uppercase;
  font-size: $fontSizeSmall;
  letter-spacing: 2px;
  text-align: center;
  background: $grey-700;

  &--big {
    padding: 5px;

    @include media('>=tablet') {
      padding: 10px 15px;
    }
  }

  &--sale {
    background: $brandPrimaryColor;
  }

  &--new {
    background: $grey-900;
  }
}
