.address-book {
  &__headline {
    font-weight: $fontWeightSemiBold;
    font-size: $fontSizeMedium;
    margin-bottom: 20px;
  }

  &__addresses {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;

    @include media('>=tablet') {
      margin: 0 -10px;
    }
  }

  &__address-wrapper {
    width: 100%;

    @include media('>=tablet') {
      width: 50%;
      padding: 10px;
      border-right: 1px solid $grey-200;

      &:last-child,
      &:nth-child(2n) {
        border-right: none;
      }
    }
  }
}
