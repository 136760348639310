.account-single-order {
  &__header {
    margin: 0;

    &-wrapper {
      display: flex;
      gap: 1.5rem;
    }
  }

  &__headline {
    margin-bottom: 1rem;
  }

  &__order-label {
    color: $black;
  }

  &__invoiceId {
    &::before {
      content: "\00a0";
    }
  }

  &__order-summary {
    padding: 1rem 0;
  }

  &__address-document-wrapper {
    padding: 1rem 0;

    @include media('>=laptop') {
      display: flex;
      gap: 7rem;
    }
  }

  &__details-wrapper {
    &:not(:last-child) {
      margin-bottom: 6rem;
    }

    &-header {
      font-weight: normal;
      margin-top: 2rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: .5rem 2rem;
    }

    &-badge {
      padding: .25rem .75rem;
      border: 1px solid $black;
      align-self: center;
    }
  }

  &__address-wrapper {
    @include media('>=laptop') {
      width: 80%;
      display: flex;
    }
    @include media('<laptop') {
      display: grid;
    }

    .address-wrapper {
      @include media('>=laptop') {
        flex: 1;
      }

      & + .address-wrapper {
        @include media('>=laptop') {
          flex: 1;
        }
        @include media('<laptop') {
          margin-top: 1.5rem;
        }
      }
    }
  }

  &__invoice-wrapper {
    @include media('>=laptop') {
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      align-items: end;
      flex: 0 0 33%;
    }
    @include media('<laptop') {
      margin-top: 1.5rem;
    }

    .document-align {
      text-align: end;
    }
  }

  &__button-download {
    @include iconFont(download);

    padding-left: 1.5rem;
    display: flex;
    align-items: center;
    gap: .5rem;
  }
}
