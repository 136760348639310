.select {
  $root: &;

  &__label {
    font-size: .75rem;
    display: block;

    &--only-mobile {
      @include media('>=tablet') {
        display: none;
      }
    }

    &--required {
      &::after {
        content: '*';
      }
    }
  }

  &__wrapper {
    width: 100%;
    color: $black;
    background: $white;
    position: relative;
    transition: border .2s, box-shadow .2s;
    cursor: pointer;
    z-index: $indexFlyout;

    .inverse & {
      background: none;
      color: $whiteColor;
    }

    &--error #{$root}__selected-value {
      border: 1px solid $signalRedDark;
    }

    &--success #{$root}__selected-value {
      border: 1px solid $signalGreenDark;
    }

    &--disabled {
      background: $grey-100;
      cursor: not-allowed;

      #{$root}__selected-value:hover {
        border: 1px solid $grey-200;
      }

      &::before {
        display: none;
      }
    }

    &--active {
      & .select__selected-value::before {
        transform: translate(1px, calc(-50% + 2px)) rotate(180deg);
      }
    }

    &--icon {
      height: auto;
      border: none;
      padding: 0;
      display: flex;
      align-items: center;

      &::before {
        display: none;
      }
    }

    &:hover,
    &:focus,
    &:active {
      @include media('>=laptop') {
        border-color: $black;

        .inverse & {
          border-color: $white;
          box-shadow: 0 0 .5rem rgb(0 0 0 / 50%);
        }
      }
    }
  }

  &__field {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:focus {
      outline: none;
    }
  }

  &__error {
    @include fontSize($baseFontSize);

    color: $signalRedDark;
    display: block;
    margin-top: 8px;
  }

  &__custom-dropdown {
    position: absolute;
    top: calc(100% - 1px);
    max-width: 400px;
    min-width: 100%;
    left: 0;
    overflow: hidden hidden;
    transition: max-height .2s, opacity .1s;
    background: $white;
    border: 1px solid $black;

    .inverse & {
      background: $black;
      color: $whiteColor;
      border: 1px solid $white;
    }

    &--expand-top {
      top: auto;
      bottom: calc(100% - 1px);
    }

    &--icon {
      left: -10px;
      width: auto;
      margin-top: 10px;
    }
  }

  &__selected-value,
  &__custom-option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: .5rem 2rem .5rem 1rem;
    position: relative;

    &--align-right {
      justify-content: flex-end;
    }
  }

  &__selected-value {
    width: 100%;
    position: relative;
    border: 1px solid $black;

    @include iconFont(arrowDown) {
      position: absolute;
      top: 50%;
      right: .375rem;
      z-index: 1;
      font-size: 1.5rem;
      color: $grey-900;
      transform: translate(0, -50%);

      .inverse & {
        color: $whiteColor;
      }
    }

    .inverse & {
      border: 1px solid $grey-200;
    }

    &--icon {
      color: $black;
      text-decoration: underline;
      font-size: $fontSizeSmall;
      padding: 0;
      height: 13px;
    }
  }

  &__custom-option {
    margin: 1px;
    white-space: nowrap;
    width: 100%;

    &:last-child {
      border-bottom: none;
    }

    &--selected {
      font-weight: $fontWeightSemiBold;
    }

    &:hover,
    &:focus,
    &:active {
      @include media('>=laptop') {
        background: $grey-100;

        .inverse & {
          @include media('>=laptop') {
            color: $white;
            background: $grey-900;
          }
        }
      }
    }

    &--icon {
      min-height: 30px;
      font-size: $fontSizeSmall;
      font-weight: $fontWeightMedium;
      padding: 0 10px;

      &--selected {
        display: none;
      }
    }
  }

  &__selected-value-image,
  &__option-value-image {
    max-width: 26px;
    display: block;
    height: auto;
    margin-right: 15px;
  }

  &__without-border {
    .select__selected-value {
      border-width: 0 0 1px;
      /* // assuming that the borderless select always should have a label above it */
      padding: .25rem 1.5rem .75rem 0;
      transition: padding ease .2s;

      &::before {
        right: 0;
        top: 1rem;
      }
    }

    &.inverse {
      .select__selected-value {
        border-width: 0;
      }
    }

    .select__custom-option {
      padding: .25rem .5rem;
    }
  }

  &__small {
    .select__selected-value {
      padding-top: 0;
      padding-bottom: 0;

      &::before {
        top: 45%;
      }
    }
  }
}
