/* stylelint-disable value-keyword-case */
$icons: (
  burgerMenu: $aui-icon-off-canvas-small,
  close: $aui-icon-cancel-small,
  checked: $aui-icon-select-small,
  user: $aui-icon-user-small,
  camera: $aui-icon-camera-small,
  heart: $aui-icon-favorite-small,
  heart-full: $aui-icon-favorite-filled-small,
  trash: $aui-icon-erase-small,
  reload: $aui-icon-replay-small,
  search: $aui-icon-search-small,
  mail: $aui-icon-direct-mail-small,
  phone: $aui-icon-voice-call-small,
  logout: $aui-icon-logout-small,
  cart: $aui-icon-shopping-basket-small,
  info: $aui-icon-system-info-small,
  engine: $aui-icon-engine-small,
  // TODO: might need to add a different icon for full cart
  cart-full: $aui-icon-shopping-basket-small,
  edit: $aui-icon-edit-small,
  shipping: $package-small,
  delivery: $delivery-l,
  sadbrowser: $aui-icon-languages-small,
  arrowDown: $aui-icon-arrow-down-small,
  arrowLeft: $aui-icon-back-small,
  arrowUp: $aui-icon-arrow-up-small,
  arrowRight: $aui-icon-forward-small,
  // TODO: using the same icons as for not bold arrows might lead to UI issues -> check
  arrowDownBold: $aui-icon-arrow-down-small,
  arrowLeftBold: $aui-icon-back-small,
  arrowUpBold: $aui-icon-arrow-up-small,
  arrowRightBold: $aui-icon-forward-small,
  options: $aui-icon-settings-small,
  loading: $aui-icon-refresh-small,
  youtube: $aui-icon-youtube-small,
  video: $aui-icon-video-small,
  linkedin: $aui-icon-linkedin-small,
  instagram: $aui-icon-instagram-small,
  facebook: $aui-icon-facebook-small,
  googleplus: $aui-icon-google-plus-small,
  twitter: $aui-icon-twitter-small,
  headset: $aui-icon-hotline-small,
  return: $aui-icon-refresh-small,
  time: $aui-icon-time-small,
  zoomIn: $zoom-in-small,
  fullScreen: $aui-icon-resize-increase-small,
  exitFullScreen: $aui-icon-resize-decrease-small,
  add: $plus-small,
  switch: $switch-small,
  switch-off: $switch-off-small,
  eye: $aui-icon-eye-small,
  eye-blocked: $aui-icon-eye-crossed-small,
  check-mark: $aui-icon-select-small,
  // TODO: check if icon is appropriate
  verified: $aui-icon-system-select-small,
  gift: $aui-icon-gift-small,
  person: $aui-icon-user-small,
  group: $aui-icon-groups-small,
  car-search: $aui-icon-car-search-small,
  unlock: $aui-icon-lock-unlock-small,
  time-small: $aui-icon-time-small,
  car: $aui-icon-car-small,
  car-small-crossed: $aui-icon-car-small,
  download: $aui-icon-download-small,
  dealer: $aui-icon-my-dealer-small
);
/* stylelint-enable value-keyword-case */

@mixin iconFont($icon, $pseudoElementType: before, $useCustomIconFont: false) {
  // uses Audi Iconfont as default -> audi-iconfont doesn't contain all needed icons.
  // Therefore we have an additional icon font that fills this gap.
  $iconFontFamily: 'audi-iconfont', sans-serif;

  @if $useCustomIconFont {
    $iconFontFamily: 'iconfont', sans-serif;
  }

  &::#{$pseudoElementType} {
    content: map-get($icons, $icon);
    font-family: $iconFontFamily;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    display: inline-block;
    line-height: 1;
    font-size: 1.5rem;
    text-decoration: inherit;
    text-rendering: optimizelegibility;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    @content;
  }
}
