.one-page-checkout {
  @include hideScrollBar;

  .page-main {
    padding-bottom: 0;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  p {
    color: $grey-800;
  }

  &__wrapper {
    display: grid;
    width: 100vw;
    min-height: 100vh;
    grid-template-columns: 1fr;

    @include media(">=laptop") {
      grid-template-rows: 1fr auto;
      grid-template-columns: repeat(2, 1fr);
    }

    @include media(">=tv") {
      grid-template-columns: 1fr 60rem;
    }
  }

  &__mobile-separator {
    @include media(">=laptop") {
      display: none;
    }
  }

  &__user-section,
  &__basket-section,
  &__footer {
    padding: 1rem;

    @include media(">=tablet") {
      padding: 2rem;
    }
  }

  &__user-section {
    background: $white;
    width: 100%;

    &-back-button {
      display: inline-flex;
      gap: .25rem;
      align-items: center;
      text-decoration: none;
      color: $black;
      margin: 1rem 0;

      @include media(">=laptop") {
        margin-top: 2rem;
      }

      @include iconFont(arrowLeft, before) {
        font-size: 1.5rem;
        margin-left: -.25rem;
      }
    }

    &-hint {
      color: $grey-500;
    }

    &-details {
      margin-top: 1rem;
    }
  }

  &__header--count {
    /* stylelint-disable-next-line */
    @extend .cart-page__header--count;

    display: none;

    @include media(">=laptop") {
      display: block;
    }
  }

  &__basket-section {
    background-color: white;

    @include media(">=laptop") {
      background-color: $grey-050;
      grid-column: 2;
      grid-row: 1 / 3;
    }

    &-inner {
      background-color: white;

      @include media(">=laptop") {
        padding: 1.5rem;
      }

      .headline {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
      }

      .cart-page__content__product {
        border-bottom: 0;
      }

      .order-summary__row--main {
        border-top: 0;
      }
    }

    &-legal-info {
      font-size: $fontSizeSmall;
      color: $grey-800;
      text-align: left;

      @include media(">=tablet") {
        text-align: right;
      }
    }
  }

  &__submit {
    text-align: right;

    button {
      @include media("<laptop") {
        width: 100%;
      }
    }
  }

  &__showMore {
    cursor: pointer;
    text-align: center;
    padding-bottom: 1rem;
  }

  &__footer {
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      @include media(">=tablet") {
        flex-direction: row;
        justify-content: start;
        gap: 2rem;
      }

      a {
        text-decoration: none;
        color: $grey-500;

        &:hover,
        &:focus,
        &:active {
          color: $black;
        }
      }
    }
  }

  &__error-wrapper {
    padding-top: 1rem;
  }

  &__error-message {
    color: $signalRedDark;
  }
}
