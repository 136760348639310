.page-overlay {
  position: absolute;
  inset: 0;
  background: rgb(0 0 0 / 80%);
  visibility: hidden;
  opacity: 0;
  transition: visibility .3s, opacity .3s;
  z-index: $indexPageOverlay;

  &--visible {
    visibility: visible;
    opacity: 1;
  }
}
