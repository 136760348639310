.footer-newsletter {
  &__headline {
    font-size: $baseFontSize;
    font-weight: $fontWeightThin;
    display: inline-block;
    color: $grey-500;
    width: 100%;
    padding: 5px 0 20px;
    position: relative;
  }

  &__text {
    display: block;
    color: $grey-500;
    font-size: $baseFontSize;
    line-height: 18px;
    font-weight: $fontWeightThin;
  }

  &__button {
    width: 100%;
  }

  &__success {
    @include fontSize;

    display: block;
    color: $signalGreenDark;
    margin-top: 8px;
    position: relative;
    z-index: 5;
  }
}
