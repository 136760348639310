@use "sass:math";

$columnCount: 12;

.form {
  width: 100%;

  /* // group and rows with cols */
  &__group {
    margin-bottom: 12px;
    position: relative;

    &--row {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      margin-left: -5px;
      margin-right: -5px;
    }

    &--inline {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      margin-left: -10px;
      margin-right: -10px;

      & > * {
        margin: 0 10px;
      }
    }
  }

  @for $i from 1 through $columnCount {
    &__col-#{$i} {
      padding: 0 5px;
      width: 100%;

      @include media('>=tablet') {
        width: calc(100% / #{math.div($columnCount, $i)});
      }
    }
  }

  /* // elements */
  &__headline {
    margin-bottom: 1rem;
    color: $white;
    background: $black;
    border: none;

    @include iconFont(back) {
      padding-right: 1.6rem;
    }

    @include media('>=tablet') {
      margin-top: 2rem;
    }
  }

  &__link {
    display: inline-block;
    color: $grey-600;
    text-decoration: underline;

    &:visited {
      color: $grey-600;
    }

    &:hover {
      color: $brandPrimaryColor;
    }
  }

  &__text {
    margin: 5px 0 10px;
  }

  &__submit_button {
    width: 100%;
    margin-top: 1rem;
  }
}
