.filter {
  $root: &;

  &__options {
    overflow-y: auto;
    max-height: 160px;
    padding-bottom: 20px;

    &--swatches {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &__option {
    display: block;
    position: relative;
    padding: 0 0 2px 30px;
    margin-bottom: 10px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &--swatch {
      width: 42px;
      height: 42px;
      padding-left: 0;
      margin-right: 6px;
      border: 2px solid $grey-200;
    }

    &--swatch-active {
      border-color: $black;
    }

    &--disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }

  &__input {
    display: none;

    &:checked {
      + #{$root}__box {
        border: 1px solid $grey-900;
        background-color: $grey-900;

        @include iconFont(checked) {
          color: $white;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &--swatch {
          border-color: transparent;

          &::before {
            display: none;
          }
        }

        + #{$root}__label {
          font-weight: $fontWeightSemiBold;
        }
      }
    }
  }

  &__box {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid $grey-200;
    position: absolute;
    left: 0;
    top: 0;

    &--swatch {
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      border-color: transparent;
      position: absolute;
      top: 3px;
      left: 3px;
    }
  }

  &__label {
    line-height: 24px;

    &--swatch {
      display: none;
    }
  }

  &__option-product-count {
    color: $grey-500;
  }

  &__search {
    width: 100%;
    margin-bottom: 20px;
  }
}
