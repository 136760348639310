.user-area {
  @include sideColumnLayout;

  position: relative;

  @include media('<=tablet') {
    padding: 0;
  }

  @include media('>=laptop') {
    padding-left: 1rem;
  }

  &__column {
    display: block;
  }

  &__name {
    margin: 1rem 0 1.5rem;
    text-align: left;
    padding: 0;
    text-transform: none;

    @include media('>tablet') {
      margin: 1.5rem 0 3.25rem;
    }

    @include media('<=tablet') {
      padding-left: 1rem;
    }
  }

  &__content {
    @include media('<=tablet') {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  /* ToDo delete if not exist in new accountPage Layout */
  &__logout-mobile {
    @include fontSize;

    position: absolute;
    top: 0;
    right: 20px;
    display: block;
    text-decoration: none;
    color: $grey-900;

    @include iconFont(logout, before) {
      font-size: $fontSizeXXMedium;
      display: block;
      width: 20px;
      margin: 0 auto 5px;
    }

    @include media('>=tablet') {
      display: none;
    }
  }
}
