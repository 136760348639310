.product-page-pagination {
  display: none;

  @include media('>=tablet') {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
  }

  &__product {
    @include media('>=tablet') {
      width: auto;
      height: 21px;
      display: block;
      color: $grey-600;
      text-decoration: none;
      position: relative;
    }

    &:hover,
    &:focus {
      &::after,
      &::before {
        color: $brandPrimaryColor;
      }
    }

    &--prev {
      padding-left: 20px;

      @include iconFont(arrowRight) {
        position: absolute;
        top: calc(50% - 12px);
        font-size: $baseFontSize;
        color: $grey-600;
        transition: color .2s;
        transform: rotate(180deg);
        left: 0;
      }
    }

    &--next {
      padding-right: 20px;
      margin-left: auto;

      @include iconFont(arrowRight, after) {
        position: absolute;
        top: calc(50% - 12px);
        font-size: $baseFontSize;
        color: $grey-600;
        transition: color .2s;
        right: 0;
      }
    }
  }
}
