.minicart {
  &__products {
    height: auto;
    margin: 0 15px 15px;
  }

  &__products-scroll {
    height: 320px;
    overflow-y: scroll;
    margin: 0 15px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__product-order-position {
    border-bottom: 1px solid $grey-200;

    &:last-child {
      border-bottom: none;
    }
  }

  &__product {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__product-image {
    width: 60px;
    height: auto;
    display: block;
  }

  &__product-details {
    width: calc(100% - 80px);
    display: flex;
    flex-direction: column;
  }

  &__product-name {
    display: block;
    font-size: $baseFontSize;
    color: $grey-900;
    margin-bottom: 5px;
  }

  &__product-nr,
  &__product-quantity {
    display: block;
    font-size: $fontSizeSmall;
    color: $grey-500;
    margin-top: 5px;
  }

  &__message {
    display: block;
    font-size: $baseFontSize;
    color: $grey-900;
    margin: 10px 0;
  }

  &__button {
    display: block;
    width: 300px;
    margin: 10px 0;
  }

  &__close {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    background: $brandPrimaryColor;
    z-index: 4;

    &::before,
    &::after {
      content: '';
      width: 2px;
      height: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -5px 0 0 -1px;
      border-radius: 2px;
      background: $white;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__header {
    border-bottom: 1px solid $grey-200;
    height: 30px;
    font-size: $fontSizeMedium;
    font-weight: $fontWeightMedium;
    margin: 15px 15px 5px;

    &--add {
      font-weight: $fontWeightSemiBold;
      height: 50px;
    }

    &--added {
      @include iconFont(verified, after) {
        color: $signalGreenDark;
      }
    }
  }

  &__footer {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--many {
      box-shadow: 0 -2px 5px rgb(0 0 0 / 30%);
      position: relative;
    }
  }

  &__price-box {
    height: 30%;
    width: 100%;
    background: $grey-200;
  }

  &__footer-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 15px;
    padding: 5px 0;

    &:first-child {
      padding: 15px 0 5px;
    }

    &:last-child {
      padding-top: 5px;
    }
  }

  &__discount {
    font-size: $fontSizeXSmall;
  }

  &__shipping {
    font-size: $fontSizeXSmall;
  }

  &__total {
    font-size: $fontSizeMedium;

    > small {
      font-size: $fontSizeXSmall;
    }
  }

  &__empty {
    height: 130px;
    padding: 15px;
  }
}
