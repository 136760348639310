.range-slider {
  &__range {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: relative;

    &::before {
      content: '';
      top: 50%;
      left: 50%;
      margin: -.125rem 0 0 -.25rem;
      width: 8px;
      height: 1px;
      background: $grey-200;
      position: absolute;
    }
  }

  &__current-value {
    width: calc(50% - 10px);
    display: block;
    height: 42px;
    line-height: 41px;
    cursor: pointer;
    font-size: $baseFontSize;
    border: 1px solid $grey-200;
    text-align: center;

    @include media('>=tablet', '<laptop') {
      font-size: .9rem;
    }
  }

  &__current-value-edit {
    width: calc(50% - 10px);
    height: 42px;
    border: 1px solid $grey-200;
    background: none;
    font-size: $baseFontSize;
    text-align: center;

    @include media('>=tablet', '<laptop') {
      font-size: .9rem;
    }

    &:focus,
    &:active {
      outline: none;
      box-shadow: 0 0 .625rem 0 rgb(0 0 0 / 25%);
      border: 1px solid $brandPrimaryColor;
    }
  }

  &__slider {
    width: calc(100% - 1.25rem);
    margin: 1.875rem .625rem 0;
    direction: ltr;
    position: relative;

    &::before,
    &::after {
      content: '';
      width: 10px;
      height: 3px;
      background: $grey-200;
      position: absolute;
      top: 0;
    }

    &::before {
      left: 100%;
    }

    &::after {
      right: 100%;
    }

    @include media('<=laptop') {
      margin: .94rem 0 1.875rem .625rem;
    }

    .noUi-base {
      width: 100%;
      position: relative;
      z-index: 1;
    }

    .noUi-connects {
      width: 100%;
      height: 3px;
      background: $grey-200;
      position: relative;
      overflow: hidden;
    }

    .noUi-connect {
      will-change: transform;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transform-origin: 0 0;
      background: lighten($brandPrimaryColor, 10%);
    }

    .noUi-origin {
      will-change: transform;
      position: absolute;
      z-index: 1;
      top: 0;
      left: auto;
      right: 0;
      height: 100%;
      width: 100%;
      transform-origin: 0 0;
    }

    .noUi-handle {
      position: absolute;
      width: 22px;
      height: 22px;
      right: -.7rem;
      top: 50%;
      margin-top: -.7rem;
      background: $brandPrimaryColor;
      border: 2px solid $white;
      border-radius: 100%;
      transition: transform .2s;
    }

    .noUi-active {
      transform: scale(1.2);
    }
  }
}
