.table {
  $root: &;

  display: block;
  width: 100%;
  border-spacing: 0;
  text-align: left;
  border: 1px solid $grey-100;
  border-radius: 5px;

  @include media('>=tablet') {
    display: table;
  }

  &__body {
    width: 100%;
    display: block;

    @include media('>=tablet') {
      display: table-row-group;
    }
  }

  &__row {
    background: $white;
    display: block;

    @include media('>=tablet') {
      display: table-row;
    }

    &:nth-child(2n) {
      background: $grey-050;
    }

    th {
      font-weight: $fontWeightRegular;
      line-height: 1.4;
      color: $grey-900;
      padding: 10px 20px;
      font-size: $baseFontSize;
      display: block;

      @include media('>=tablet') {
        display: table-cell;
      }
    }

    &--mobile-hidden {
      visibility: hidden;
      height: 0;
      position: relative;
      z-index: -1;

      @include media('>=tablet') {
        visibility: visible;
        height: auto;
        position: relative;
        z-index: auto;
      }
    }

    &--inverted {
      background: $grey-050;

      &:nth-child(2n) {
        background: $white;
      }
    }
  }

  &__head {
    display: block;
    border-bottom: 5px solid $grey-050;

    @include media('>=tablet') {
      display: table-header-group;
    }

    &--mobile-hidden {
      visibility: hidden;
      height: 0;
      position: relative;
      z-index: -1;

      @include media('>=tablet') {
        visibility: visible;
        height: auto;
        position: relative;
        z-index: auto;
      }
    }
  }

  &__cell {
    line-height: 1.4;
    color: $grey-900;
    padding: 10px 20px;
    font-size: $baseFontSize;
    display: block;

    @include media('>=tablet') {
      display: table-cell;
    }

    &--mobile-label {
      &::before {
        content: attr(data-title);
        margin-right: 10px;
        font-weight: $fontWeightSemiBold;
        line-height: 1.4;
        color: $grey-900;
        font-size: $baseFontSize;
      }

      @include media('>=tablet') {
        &::before {
          display: none;
          content: '';
        }
      }
    }

    &--with-image {
      display: flex;
      align-items: center;
    }
  }

  &__image {
    display: inline-block;
    max-width: 80px;
    height: auto;
    max-height: 50px;
    padding: 0 5px;
  }

  &--initial-table {
    overflow-x: auto;
    display: table;

    tr,
    #{$root}__row {
      display: table-row;
    }

    td,
    #{$root}__cell {
      display: table-cell;
      padding: 10px;
    }

    tbody,
    #{$root}__body {
      display: table;
    }
  }
}
