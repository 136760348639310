.burger-nav-tree {
  @include clearList;

  margin: 0;
  position: relative;
  display: block;
  background: $white;

  &__subcategories {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translate3d(100%, 0, 0);
    transition: transform .3s;
    background: $white;

    &--active {
      transform: translate3d(0, 0, 0);
    }
  }

  &__item {
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;

    &--headline {
      color: $brandPrimaryColor;
      font-size: $fontSizeSmall;
      text-transform: uppercase;
      padding: 15px 0 15px 20px;
      position: relative;
      border-bottom: 1px solid $grey-100;

      @include iconFont(arrowRight) {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        font-size: 10px;
        color: $brandPrimaryColor;
      }
    }

    &--category-special {
      padding: 5px 0;
      border-bottom: 1px solid $grey-100;
    }

    &--subcategory {
      &_first {
        border-top: 1px solid $grey-100;
        padding-top: 1rem;
      }

      &_last {
        padding-bottom: 5px;
      }
    }

    &--bottom-link {
      &_first {
        border-top: 1px solid $grey-100;
        padding-top: 5px;
      }
    }
  }

  &__link {
    display: block;
    position: relative;
    padding: .5rem 0;
    color: $black;
    text-decoration: none;
    font-stretch: 130%;
    letter-spacing: -.03em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 150%;

    &:visited {
      color: $black;
    }

    &--highlighted {
      color: $brandPrimaryColor;
    }

    &--disabled {
      color: $grey-300;
      cursor: not-allowed;
    }

    &--arrow {
      @include iconFont(arrowRight) {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 12px;
        color: $brandPrimaryColor;
      }
    }
  }
}
