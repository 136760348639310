.product-flags {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 10px;

  @include media('>=laptop') {
    right: 20px;
    top: 0;

    @include iconFont(zoomIn, before, true) {
      color: $black;
      font-size: $fontSizeXXXMedium;
      position: absolute;
      left: -35px;
      z-index: 1;
      top: 5px;
    }
  }

  &__label {
    display: block;
    margin-bottom: 5px;
  }
}
