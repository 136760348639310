.simple-image-teaser {
  display: block;
  width: 100%;

  &__image {
    display: block;
    width: 100%;
    height: auto;
  }
}
