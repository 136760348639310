.html-teaser {
  font-size: $baseFontSize;
  color: $black;
  font-family: inherit;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    width: 100%;
    margin: .5rem 0;
  }

  h1 {
    /* stylelint-disable-next-line */
    @extend .h1;

    hyphens: manual;

    @include media('<=tablet') {
      font-size: $fontSizeXLarge;
    }
  }

  h2 {
    /* stylelint-disable-next-line */
    @extend .h2;

    hyphens: manual;

    @include media('<=tablet') {
      font-size: $fontSizeLarge;
    }
  }

  h3 {
    /* stylelint-disable-next-line */
    @extend .h3;
  }

  h4,
  h5,
  h6 {
    /* stylelint-disable-next-line */
    @extend .default-bold;
  }

  p {
    font-size: $baseFontSize;
    color: $grey-900;
    margin: .5rem 0;

    &:last-child {
      margin-bottom: 0;
    }

    strong,
    b {
      margin: 0;
      color: $black;
      font-weight: $fontWeightSemiBold;
    }
  }

  strong {
    font-weight: $fontWeightSemiBold;
    color: $black;
  }

  blockquote {
    padding: .6rem 1.3rem;
    margin: 0 0 1.3rem;
    line-height: 1.4;
    font-size: $fontSizeMedium;
    border-left: .3rem solid $grey-100;
  }

  a,
  a:visited {
    color: $black;
    font-size: $baseFontSize;
    text-transform: none;
    text-decoration: underline;

    &:hover {
      color: $brandPrimaryColor;
    }
  }

  li {
    margin: 0 0 .5rem;
  }

  table {
    width: 100%;
    display: block;
    overflow-x: auto;
    border-collapse: collapse;
    border: 0;

    @include media('>=laptop') {
      width: 90%;
    }

    td,
    th {
      text-align: left;
      padding: .5rem .5rem 0 0;
      vertical-align: top;
    }
  }

  .table-content {
    thead {
      background: $white;

      td:first-child {
        padding-left: .5em;
      }
    }

    td,
    th {
      padding: .5rem;
    }

    @include media('<=tablet') {
      overflow: scroll;

      table {
        height: auto !important;
        table-layout: fixed;
      }

      thead {
        display: table;
        width: calc(100% - 18px);
      }

      tbody {
        height: 25rem;
        overflow: auto;
        overflow-x: hidden;
        display: block;
        width: 100%;

        tr {
          display: table;
          width: 100%;
          table-layout: fixed;
        }

        td:first-child {
          padding-left: .5em;
        }
      }
    }
  }

  /* OSSE-327 */
  .bg-red {
    color: $black;
    background: $brandAccentColor;
  }

  .td-standard {
    border-style: solid;
    border-width: 3px;
    border-color: $black;

    td {
      border-style: solid;
      border-width: 3px;
      border-color: $black;
    }
  }

  .td-quality-text {
    background: $progressiveRed;
    color: $white;
  }

  .td-text {
    font-size: large;
    font-weight: 600;
  }

  .table-text {
    font-size: small;
  }

  .first-text {
    font-weight: 600;
  }

  .font-red {
    color: $progressiveRed;
  }

  .border-bottom-dots {
    border-bottom: 1px dashed $grey-600 !important;
  }

  .td-quality-l {
    border-left-color: $progressiveRed;
    border-right-width: 0 !important;
  }

  .td-quality-m {
    border-left-width: 1px !important;
    border-left-color: $white !important;
    border-right-width: 1px !important;
    border-right-color: $white !important;
  }

  .td-quality-r {
    border-right-color: $progressiveRed;
    border-left-width: 0 !important;
  }

  .border-left-red {
    border-left-color: $progressiveRed !important;
  }

  .border-right-red {
    border-right-color: $progressiveRed !important;
  }

  .border-top-red {
    border-top-color: $progressiveRed !important;
  }

  .border-bottom-red {
    border-bottom-color: $progressiveRed !important;
  }

  .border-left-none {
    border-left-width: 0 !important;
  }

  .border-right-none {
    border-right-width: 0 !important;
  }

  .border-top-none {
    border-top-width: 0 !important;
  }

  .border-bottom-none {
    border-bottom-width: 0 !important;
  }
}

.quality-classification {
  ul {
    list-style: none;

    ul {
      list-style: disc;

      ul {
        list-style: none;
      }
    }
  }

  &__footer {
    color: $grey-400;
    display: flex;
    margin-top: 1.5rem;
    margin-bottom: .25rem;
    justify-content: space-between;
  }
}
