.nav-all-categories {
  @include media('>laptop') {
    $root: &;

    &--flyout-visible {
      width: 100%;
      display: flex;
    }

    &__main-column {
      width: 200px;
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;

      &--flyout-visible {
        &::after {
          content: '';
          width: 1px;
          height: 100%;
          position: absolute;
          right: -1px;
          top: 0;
          box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%);
          z-index: 1;
        }
      }
    }

    &__list-wrapper {
      flex: 1 1 auto;
    }

    &__list {
      @include clearList;

      margin: 0;
      padding: 0;
    }

    &__item {
      display: block;
      margin: -1px 0 0;

      &:first-child {
        margin-top: 0;
      }
    }

    &__link {
      display: block;
      padding: 10px 20px;
      border-bottom: 1px solid $grey-100;
      border-top: 1px solid transparent;
      color: $grey-900;
      text-decoration: none;
      position: relative;

      @include iconFont(arrowRight) {
        font-size: $fontSizeXSmall;
        font-weight: $fontWeightSemiBold;
        color: $grey-900;
        position: absolute;
        left: 3px;
        top: calc(50% - (#{$fontSizeXSmall} / 2));
      }

      &:hover,
      &:visited {
        color: $grey-900;
        text-decoration: none;
      }

      &:hover,
      &--active {
        color: $brandPrimaryColor;
        border-color: $brandPrimaryColor;

        &::before {
          color: $brandPrimaryColor;
        }
      }
    }

    &__link-icon {
      font-size: 15px;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }

    &__navigation-flyout {
      display: none;
      width: calc(100% - 200px);
      z-index: 2;
      position: relative;

      &--visible {
        display: flex;
        background: $white;
      }
    }
  }
}
