.recommendations-teaser {
  margin-bottom: 3rem;

  &__slider {
    position: relative;
    padding: 0 30px;
  }

  &__container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
  }

  &__slides {
    position: relative;
    width: 100%;
    display: flex;
  }

  &__slide {
    width: 100%;
    display: block;
    position: relative;
    flex-shrink: 0;
    max-width: calc(100% / 2);
    margin-right: 10px;

    @include media('>=tablet') {
      max-width: calc(100% / 3);
      margin-right: 20px;
    }

    @include media('>=laptop') {
      max-width: calc(100% / 4);
      margin-right: 30px;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
  }

  &__headline {
    margin-bottom: 2rem;
  }

  &__button {
    display: block;
    width: 20px;
    height: 25px;
    text-decoration: none;
    position: absolute;
    top: calc(50% - 12px);
    z-index: 1;

    @include iconFont(arrowRight) {
      font-size: $fontSizeXXMedium;
      color: $grey-600;
      transition: color .2s;
    }

    &:hover,
    &:focus {
      &::before {
        color: $brandPrimaryColor;
      }
    }

    &--prev {
      left: 0;

      &::before {
        transform: rotate(180deg);
      }
    }

    &--next {
      right: 0;
    }

    &--disabled {
      visibility: hidden;
    }
  }

  &__additional-content {
    display: none;
    flex: 1;

    &--visible {
      display: block;
    }
  }
}
