/* // Fonts */
@mixin fontSize($fontSize: $baseFontSize) {
  @if $fontSize == $fontSizeXSmall {
    font-size: 8px;

    @include media('>=laptop') {
      font-size: $fontSizeXSmall;
    }
  } @else if $fontSize == $fontSizeSmall {
    font-size: 10px;

    @include media('>=laptop') {
      font-size: $fontSizeSmall;
    }
  } @else if $fontSize == $baseFontSize {
    font-size: 12px;

    @include media('>=laptop') {
      font-size: $baseFontSize;
    }
  } @else if $fontSize == $fontSizeMedium {
    font-size: 13px;

    @include media('>=tablet') {
      font-size: 14px;
    }

    @include media('>=laptop') {
      font-size: $fontSizeMedium;
    }
  } @else if $fontSize == $fontSizeXMedium {
    font-size: 14px;

    @include media('>=tablet') {
      font-size: 16px;
    }

    @include media('>=laptop') {
      font-size: $fontSizeXMedium;
    }
  } @else if $fontSize == $fontSizeXXMedium {
    font-size: 16px;

    @include media('>=tablet') {
      font-size: 18px;
    }

    @include media('>=laptop') {
      font-size: $fontSizeXXMedium;
    }
  } @else if $fontSize == $fontSizeXXXMedium {
    font-size: 20px;

    @include media('>=tablet') {
      font-size: 24px;
    }

    @include media('>=laptop') {
      font-size: $fontSizeXXXMedium;
    }
  } @else if $fontSize == $fontSizeLarge {
    font-size: 20px;

    @include media('>=tablet') {
      font-size: 24px;
    }

    @include media('>=laptop') {
      font-size: $fontSizeLarge;
    }
  } @else if $fontSize == $fontSizeXLarge {
    font-size: 24px;

    @include media('>=tablet') {
      font-size: 28px;
    }

    @include media('>=laptop') {
      font-size: $fontSizeXLarge;
    }
  } @else if $fontSize == $fontSizeXXLarge {
    font-size: 28px;

    @include media('>=tablet') {
      font-size: 34px;
    }

    @include media('>=laptop') {
      font-size: $fontSizeXXLarge;
    }
  } @else if $fontSize == $fontSizeXXXLarge {
    font-size: 30px;

    @include media('>=tablet') {
      font-size: 40px;
    }

    @include media('>=laptop') {
      font-size: $fontSizeXXXLarge;
    }
  }
}
