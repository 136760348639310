.car-compatibility {
  &-level-one {
    margin-top: .5rem;

    &__active {
      font-size: $fontSizeMedium;
      display: flex;
      align-items: center;

      @include iconFont(arrowUp, after, false);
    }

    &__inactive {
      font-size: $fontSizeMedium;
      display: flex;
      align-items: center;

      @include iconFont(arrowDown, after, false);
    }
  }

  &-level-two {
    margin-left: .5rem;
    margin-top: .5rem;
  }
}
