.language-switcher {
  position: relative;
  display: flex;

  &__icon {
    width: 1rem;
    height: 1rem;
    margin-right: .5rem;

    &--option {
      margin-right: .25rem;
    }
  }

  &__select {
    > .select__field {
      text-transform: uppercase;
    }

    .select__selected-value {
      padding-right: 2rem;
      border: none;
    }
  }
}
