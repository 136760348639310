.navigation-slider {
  $root: &;

  position: relative;
  display: flex;
  align-items: center;

  @include media('>=tablet') {
    display: none;
  }

  &__wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  &__content-wrapper {
    display: block;
    width: 100%;
    overflow: hidden;
  }

  &__content {
    display: inline-block;
  }

  &__list {
    @include clearList;

    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: scroll;
    max-width: 100%;
    position: relative;
    display: inline-flex;
    gap: 2rem;

    /* // Firefox */
    scrollbar-width: none;

    /* // Chrome */
    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }

    &--width_auto {
      display: inline-block;
    }
  }

  &__button {
    @include gradientWhiteToTransparent;

    display: none;
    align-items: center;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5rem;
    z-index: 1;

    @include iconFont(arrowLeft, before) {
      transform: rotate(0deg);
    }

    &.right {
      @include gradientTransparentToWhite;

      right: 0;
      left: auto;
      justify-content: flex-end;

      &::before {
        transform: rotate(180deg);
      }
    }

    &--rotate {
      &::before {
        transform: rotate(0deg);
      }
    }

    &--active {
      display: flex;
    }
  }
}
