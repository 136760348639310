.product-variations {
  &__list {
    @include clearList;

    display: flex;
    flex-wrap: wrap;
    margin: 0 0 10px;
    padding: 0 0 10px 5px;
    border-bottom: 1px solid $grey-100;

    &:last-child {
      margin: 0;
      border-bottom: 0;
    }
  }

  &__name {
    font-size: $baseFontSize;
    padding-bottom: 10px;
    font-weight: $fontWeightSemiBold;
    display: block;
  }

  &__variation {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}
