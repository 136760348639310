.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__button {
    cursor: pointer;
    color: $grey-900;
    display: flex;
    transition: color .2s;

    &:hover {
      color: $brandPrimaryColor;
    }

    &--prev {
      @include iconFont(arrowLeft) {
        font-size: $fontSizeLarge;
        font-weight: $fontWeightSemiBold;
      }

      margin-right: 10px;
    }

    &--next {
      @include iconFont(arrowRight) {
        font-size: $fontSizeLarge;
        font-weight: $fontWeightSemiBold;
      }

      margin-left: 10px;
    }

    &--disabled {
      cursor: not-allowed;
      opacity: .5;

      &:hover {
        color: $grey-900;
      }
    }
  }

  &__items-per-page {
    align-items: center;
    display: flex;
    margin-right: 2rem;

    &--select {
      margin-right: 1rem;
      flex-shrink: 0;
    }

    @include media('<laptop') {
      display: none;
    }
  }

  &__select {
    height: 40px;
    appearance: none;
    padding: 0 15px;
    margin: 0 10px;
    border: 1px solid $grey-200;
    border-radius: 0;
    transition: border .2s, box-shadow .2s;
    background: $white;
    cursor: pointer;
    color: $grey-500;
    font-size: $baseFontSize;

    &:hover,
    &:focus {
      border: 1px solid $brandPrimaryColor;
    }

    &:focus {
      box-shadow: 0 0 10px 0 rgb(0 0 0 / 25%);
      outline: none;
    }

    &--disabled {
      cursor: not-allowed;

      &:hover {
        border: 1px solid $grey-200;
      }
    }
  }

  &__page-current,
  &__pages-count {
    margin-left: .5rem;
  }

  &__page-current {
    margin-right: .5rem;
  }

  &__label {
    color: $grey-500;
  }

  &__bottom-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;

    @include media('<laptop') {
      justify-content: center;
    }
  }

  .select__selected-value {
    padding: 0 1.5rem 0 0;

    &::before {
      top: 50%
    }
  }
}
